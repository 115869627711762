<template>
  <div id="profile">
    <div v-if="error" class="notification is-danger">
      <button class="delete" @click="error = null" />
      {{ error }}
    </div>

    <label>
      <input
          class="file-input is-hidden"
          type="file"
          name="resume"
          accept="image/png,image/jpg,image/jpeg,image/bmp"
          @change="attach"
      >
      <figure class="image is-128x128 is-rounded">
        <img
            v-if="avatar"
            :src="avatar"
            alt="Avatar"
        >
        <img
            v-else
            src="~@/assets/images/user-placeholder.png"
            alt="Placeholder image"
        >
      </figure>
    </label>

    <h1 class="title is-1">
      {{ fullname }}
    </h1>

    <h3 class="subtitle is-3">
      {{ committee.name }}
    </h3>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'profile',

  data () {
    return {
      file: null,
      error: null,
      loading: false
    }
  },

  computed: {
    ...mapGetters([
      'getSelf',
      'getCommittee',
      'getSelfCommittee'
    ]),
    self () {
      return this.getSelf
    },
    fullname () {
      return `${this.self.firstname} ${this.self.lastname}`
    },
    avatar () {
      return this.self.avatar && this.self.avatar.path
    },
    committee () {
      return this.getCommittee(this.getSelfCommittee)
    }
  },

  methods: {
    ...mapActions(['uploadImage', 'editProfile']),
    attach ({ target }) {
      this.submit(target.files[0])
    },
    async submit (file) {
      if (!file) { return }

      this.loading = true
      this.error = null

      try {
        const form = new FormData()
        form.append('file', file)
        const avatar = await this.uploadImage({ form })

        await this.editProfile({ avatar: avatar.id })
      } catch (error) {
        this.error = error.message
      }

      this.loading = false
    }
  }
}
</script>

<style scoped lang="scss">

#profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  margin-bottom: 5rem;

  figure.image {
    overflow: hidden;
    cursor: pointer;
    width: 20rem;
    height: 20rem;
    margin-bottom: 3rem;
    background-image: url("/src/assets/images/user-placeholder.png");
  }
}

</style>
