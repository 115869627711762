<template>
<div class="author">

</div>
</template>

<script>
export default {
}
</script>

<style scoped lang="scss">
@import "../../assets/_shared_scss/colors";

.author {
  border-left: 1px solid lightgray;
  padding-top: 2rem;
  padding-left: 1.5rem;
  b {
    text-transform: uppercase;
    font-size: 0.8rem;
  }
}
</style>
