<template>
  <div class="card">
    <div class="card-content">
      <div class="media">
        <div class="media-content">
          <h5 class="title is-5">{{ meeting.title }}</h5>
          <p v-if="meeting.isPast" class="subtitle is-6">le {{ startAt }}</p>
          <p v-else class="subtitle is-6">prévue le {{ startAt }}</p>
        </div>
      </div>
    </div>

    <footer v-if="meeting.isPast" class="card-footer">
      <input :id="`input-file-meeting-${meeting.id}`" class="is-hidden" type="file" @input="attach">
      <label class="card-footer-item button" :for="`input-file-meeting-${meeting.id}`">
        <span class="icon">
          <i class="fa fa-upload"></i>
        </span>
        <span>
          Téléverser
        </span>
      </label>
      <a v-if="meeting.report" class="card-footer-item button" :href="meeting.report.path">
        <span class="icon">
          <i class="fa fa-download"></i>
        </span>
        <span>
          Télécharger
        </span>
      </a>
    </footer>
    <footer v-else class="card-footer">
      <button class="card-footer-item button is-warning" @click="$emit('edit')">
        Éditer
      </button>
      <button class="card-footer-item button is-danger" @click="delete_meeting(meeting.id)">
        Supprimer
      </button>
    </footer>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import moment from 'moment'

export default {
  name: 'meeting-card',

  props: {
    meeting: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      loading: false
    }
  },

  computed: {
    startAt () {
      return moment(this.meeting.startAt).format('lll')
    }
  },

  methods: {
    ...mapActions(['uploadDocument', 'attachDocument', 'deleteMeeting']),
    async attach ({ target }) {
      this.loading = true
      this.$emit('error', null)

      try {
        const file = target.files[0]
        const { id: documentId } = await this.uploadDocument({ file })

        const id = this.meeting.id
        await this.attachDocument({ id, documentId })
      } catch (error) {
        this.$emit('error', error.message)
      }

      this.loading = false
    },
    async delete_meeting(id) {
      const res = await  this.deleteMeeting({id});
      if(res.status == 200) {
        location.reload();
      }
    }
  }
}
</script>

<style scoped>

</style>
