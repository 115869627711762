import axios from 'axios'
import store from "../store";

export default function axiosSetUp() {
  axios.interceptors.request.use(
    function (config) {

      const token = store.getters.getToken && store.getters.getToken.accessToken;
      if (token && !config.headers.Authorization) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    response => response,
    error => {
      if (error?.response?.status === 413) {
        return Promise.reject(new Error('Ce fichier est trop volumineux pour être envoyé.'))
      }
      
      if (error.response && error.response.data) {
        if (Array.isArray(error.response.data.message)) {
          error.response.data.message = error.response.data.message[0]
        }
        return Promise.reject(error.response.data)
      }
      
      return Promise.reject(new Error('Ce service est momentanément indisponible.'))
    }
  )
}
