<template src="./presentation.html"/>

<script>
    export default {
        name: "Presentation"
    }
</script>

<style lang="scss" scoped>
    @import "src/assets/_shared_scss/colors";
    @import "./presentation.scss";
</style>
