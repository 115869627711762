<template>
  <section id="qsn" class="description">
    <h1><span>Q</span>ui-sommes-nous<span>?</span></h1>
    <p>
      Le Comité social et économique (CSE) est un organisme créé par la loi pour garantir la participation des salariés aux décisions relatives à la gestion et à l'évolution économique de l'entreprise. Il a pour mission de représenter les salariés dans les domaines de l'emploi, de la formation professionnelle, de la santé, de la sécurité et des conditions de travail, de l'amélioration des conditions de vie au travail, des relations sociales et de la participation aux résultats de l'entreprise.
    </p>
    <p>
      Le CSE est un lieu privilégié pour la concertation entre employeurs et salariés, il permet de traiter de manière collective les questions liées à la vie de l'entreprise et de prendre des décisions qui ont un impact sur les salariés. Il est également un acteur clé de la mise en place de politiques de RSE (responsabilité sociétale des entreprises) dans l'entreprise.
    </p>
    <p>
      En somme, le CSE est un outil essentiel pour favoriser la participation des salariés à la vie de l'entreprise et pour améliorer les conditions de travail et de vie au travail. Il contribue ainsi à renforcer la performance de l'entreprise et à améliorer les relations sociales au sein de celle-ci.
    </p>
    <hr/>
  </section>
</template>

<script>
export default {
}
</script>

<style scoped lang="scss">
@import "src/assets/_shared_scss/colors";

.description {
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    color: $noir;
    font-weight: bolder;
    text-align: center;
    font-size: 2rem;
    margin-bottom: 2rem;
    span {
      font-size: 2.5rem;
      color: $rose;
    }
  }
  p {
    font-style: italic;
    max-width: 100ch;
    text-align: justify;
    font-size: 1.2rem;
    margin: 12px 10px;
  }
  hr {
    margin-top: 3rem;
    margin-bottom: 3rem;
    width: 90%;
    height: 2px;
    border: none;
    background-color: $rose;
  }
}
</style>
