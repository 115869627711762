import Vue from 'vue'
import Vuex from 'vuex'

import App from './App.vue'
import router from './router'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import store from './store'
import interceptor from "./helpers/interceptor";

import moment from 'moment'
moment.locale('fr');

import {library} from '@fortawesome/fontawesome-svg-core'
import {faPencilAlt, faMapMarkerAlt, faPhoneAlt, faEnvelope, faClock, faTrashAlt, faEdit, faPlus} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

library.add(faPencilAlt, faMapMarkerAlt, faPhoneAlt, faEnvelope, faClock,faTrashAlt, faEdit, faPlus)

require('./assets/main.scss')

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(VueSweetalert2);
Vue.use(Vuex);

Vue.config.productionTip = false
interceptor();

Vue.directive('focus', {
    inserted: function (el) {
        el.focus()
    }
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
