<template>
  <section>
    <h3><span>N</span>ewsletters</h3>
    <div class="columns is-multiline">
      <div v-for="article in articles" :key="article.id" class="column is-one-quarter-desktop is-half-tablet is-full-mobile">
        <ArticleCard :article="article" />
      </div>
    </div>
    <div class="is-flex is-justify-content-center">
      <router-link :to="{ name: 'committee-newsletters', params: { committeeId } }">
        TOUTES LES NEWSLETTER
      </router-link>
    </div>
  </section>
</template>

<script>
import ArticleCard from '../cards/article-card'

export default {
  name: 'committee-newsletters',

  components: {
    ArticleCard
  },

  props: {
    committeeId: {
      type: [String, Number],
      required: true
    },
    articles: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/_shared_scss/colors";

section {
  background-color: $bleu;
  margin-top: 3rem;
  padding: 1rem;

  h3 {
    color: white;
    font-size: 2rem;
    text-align: center;
  }

  span {
    color: $rose;
    font-size: 3rem;
  }

  a {
    text-decoration: none;
    background-color: white;
    border: solid 1px $rose;
    color: $rose;
    padding: 15px;
    font-weight: bold;
  }
}
</style>
