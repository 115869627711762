<template>
  <section class="container_login">
    <login_form />
  </section>
</template>


<script>
import login_form from '../components/login/login_form.vue';

export default {
  name: 'login',

  components: {
    login_form
  }
}
</script>

<style>
body{
  margin: 0
}
.container_login{
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('~@/assets/images/fond.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}
</style>
