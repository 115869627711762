import Vue from 'vue'
import ArticleService from '../../services/article_service'
import CommitteeService from "../../services/committee_service";

export default {
  state: {
    articles: {}
  },

  getters: {
    getArticle: state => id => state.articles[id.toString()]
  },

  actions: {
    async createArticle ({ commit, dispatch }, { title, content, illustration }) {
      const articleService = new ArticleService()
      const response = await articleService.createArticle({ title, content, illustration })
      commit('ARTICLE_SET', response.data)
      dispatch('loadCommittee', { id: response.data.committee.id })
      return response.data
    },
    async editArticle ({ commit, dispatch }, { id, title, content, illustration }) {
      const articleService = new ArticleService()
      const response = await articleService.editArticle(id, { title, content, illustration })
      commit('ARTICLE_SET', response.data)
      dispatch('loadCommittee', { id: response.data.committee.id })
      return response.data
    },
    async fetchArticle ({ commit, dispatch }, { id }) {
      const articleService = new ArticleService()
      const response = await articleService.getArticle(id)
      commit('ARTICLE_SET', response.data)
      dispatch('loadCommittee', { id: response.data.committee.id })
      return response.data
    },
    async deleteArticle({ dispatch }, {id}) {
      const committeeService = new CommitteeService()
      const response = committeeService.deleteArticle(id)
      dispatch('loadCommittee', { id: response.data.committee.id })
      return response
    },
  },

  mutations: {
    ARTICLE_SET (state, article) {
      Vue.set(state.articles, article.id.toString(), article)
    }
  }
}
