<template>
  <div id="register">
    <form class="box" @submit.stop.prevent="submit">
      <div v-if="error" class="notification is-danger">
        <button class="delete" @click="error = null" />
        {{ error }}
      </div>

      <h1 class="title is-3 has-text-centered	">
        Inscription
      </h1>

      <div class="columns is-multiline">
        <label class="column is-half is-flex is-justify-content-center my-5">
          <input
              class="file-input is-hidden"
              type="file"
              name="resume"
              accept="image/png,image/jpg,image/jpeg,image/bmp"
              @change="attach"
          >
          <figure class="image is-128x128">
            <img
                ref="avatar"
                src="~@/assets/images/user-placeholder.png"
                alt="Placeholder image"
                class="is-rounded"
            >
          </figure>
        </label>

        <div class="column is-half my-5" :class="{ 'is-half': isElected }">
          <div class="field">
            <div class="control">
              <label class="radio">
                <input
                    v-model="role"
                    type="radio"
                    name="role"
                    value="employee"
                    class="mr-1"
                >
                Salarié
              </label>
              <label class="radio ml-5">
                <input
                    v-model="role"
                    type="radio"
                    name="role"
                    value="elected"
                    class="mr-1"
                >
                Élu
              </label>
            </div>
          </div>
          <div v-if="isElected" class="field">
            <label class="label is-small">
              Rôle
            </label>
            <div class="control">
              <input
                  placeholder="votre rôle en tant qu'élu"
                  v-model="profession"
                  class="input is-small"
                  type="text"
              >
            </div>
          </div>
          <div v-if="isElected" class="field">
            <div class="control">
              <label class="radio">
                <input
                    v-model="isCentralCommitteeMember"
                    class="mr-1"
                    type="checkbox"
                    name="role"
                >
                Membre du CSEC
              </label>
            </div>
          </div>

          <div v-if="isElected && isCentralCommitteeMember == true" class="field">
            <label class="label is-small">
              Rôle CSEC
            </label>
            <div class="control">
              <input
                  placeholder="votre rôle en tant qu'élu"
                  v-model="professionCentralCommittee"
                  class="input is-small"
                  type="text"
              >
            </div>
          </div>

        </div>

        <div class="field column is-6">
          <label class="label">
            Adresse e-mail
          </label>
          <div class="control">
            <input
                placeholder="Uniquement personnelle"
                v-model="email"
                class="input"
                type="email"
            >
            <p v-if="email.split('@')[1] == 'ladapt.net'" class="help is-danger">
              L'adresse email doit uniquement être personelle
            </p>
          </div>
        </div>

        <div v-if="isElected" class="field column is-6">
          <label class="label">
            Adresse e-mail @ladapt.net
          </label>
          <div class="control">
            <input
                placeholder="Uniquement pour élus administrateur"
                v-model="professionalEmail"
                class="input"
                type="email"
            >
          </div>
        </div>

        <div v-if="isEmployee" class="field column is-half">
          <label class="label">
            Date de naissance
          </label>
          <div class="control">
            <input
                v-model="birthdate"
                class="input"
                type="date"
                autocomplete="bday"
            >
          </div>
        </div>

        <div class="field column is-half">
          <label class="label">
            Prénom
          </label>
          <div class="control">
            <input
                v-model="firstname"
                class="input"
                type="text"
                autocomplete="given-name"
            >
          </div>
        </div>

        <div class="field column is-half">
          <label class="label">
            Nom
          </label>
          <div class="control">
            <input
                v-model="lastname"
                class="input"
                type="text"
                autocomplete="family-name"
            >
          </div>
        </div>

        <div class="field column is-half">
          <label class="label">
            Mot de passe
          </label>
          <div class="control">
            <input
                v-model="password"
                class="input"
                :class="{ 'is-danger': password && cpassword && password !== cpassword }"
                type="password"
                autocomplete="new-password"
            >
          </div>
          <p v-if="password && cpassword && password !== cpassword" class="help is-danger">
            Les mots de passe ne correspondent pas
          </p>
        </div>

        <div class="field column is-half">
          <label class="label">
            Confirmation du mot de passe
          </label>
          <div class="control">
            <input
                v-model="cpassword"
                class="input"
                :class="{ 'is-danger': password && cpassword && password !== cpassword }"
                type="password"
                autocomplete="new-password"
            >
          </div>
        </div>

        <div v-if="isElected" class="field column is-half">
          <label class="label">
            Date de naissance
          </label>
          <div class="control">
            <input
                v-model="birthdate"
                class="input"
                type="date"
                autocomplete="bday"
            >
          </div>
        </div>

<!--        <div v-if="isElected" class="select is-multiple column is-half">-->
<!--          <label class="label">-->
<!--            Commissions-->
<!--          </label>-->
<!--          <select v-model="commission">-->
<!--            <option v-for="e of commissions" :key="e.id" :value="e.id">-->
<!--              {{ e.name }}-->
<!--            </option>-->
<!--          </select>-->
<!--        </div>-->

        <div class="column is-12 p-responsive">
          <button class="button is-success is-pulled-right mt-5" type="submit" :disabled="!canSubmit">
            Inscription
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'register',

  data() {
    return {
      loading: false,
      error: null,

      birthdate: '',
      email: '',
      commission: null,
      firstname: '',
      lastname: '',
      password: '',
      cpassword: '',
      professionalEmail: '',
      role: 'employee',
      isCentralCommitteeMember: false,
      profession: '',
      professionCentralCommittee: '',

      avatar: null,

      commissions: []
    }
  },

  computed: {
    isElected () {
      return this.role === 'elected'
    },
    isEmployee () {
      return this.role === 'employee'
    },
    canSubmit () {
      return this.birthdate && this.email && this.email.split('@')[1] !== 'ladapt.net' && this.firstname && this.lastname && this.password && this.password === this.cpassword
    }
  },

  mounted() {
    if (!this.$route.query.committeeId) {
      this.error = 'Vous devez sélectionner un comité'
    }

    this.loadEstablishments()
  },

  methods: {
    ...mapActions([
      'fetchCommissionId',
      'loginWithCredentials',
      'register',
      'uploadImage'
    ]),

    async loadEstablishments () {
      this.loading = true

      try {
        this.commissions = await this.fetchCommissionId({id: this.$route.query.committeeId})
      } catch (error) {
        this.error = error.message
      }

      this.loading = false
    },
    attach ({ target }) {
      this.avatar = target.files[0]
      if (this.$refs.avatar) {
        const reader = new FileReader();
        reader.onload = e => { this.$refs.avatar.src = e.target.result }
        reader.readAsDataURL(this.avatar);
      }
    },
    async submit () {
      this.loading = true

      try {
        let avatar
        if (this.avatar) {
          const form = new FormData()
          form.append('file', this.avatar)
          avatar = await this.uploadImage({ form })
        }

        const token = this.$route.query.token
        const { birthdate, commission, email, isCentralCommitteeMember, firstname, lastname, password, profession, role, professionCentralCommittee, professionalEmail } = this
        await this.register({ avatar: avatar?.id, birthdate, commission, email, isCentralCommitteeMember, firstname, lastname, password, token, profession, role, professionCentralCommittee, professionalEmail })
        await this.loginWithCredentials({ email, password })
      } catch (error) {
        this.error = error.message
      }

      this.loading = false
    }
  }
}
</script>

<style scoped lang="scss">
#register {
  height: 100vh;
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('~@/assets/images/fond.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  overflow-y: auto;

  figure {
    overflow: hidden;
    cursor: pointer;
  }

  form.box {
    max-width: 40rem;
  }
  select{
    width: 100%;
  }
}

@media only screen and (max-width: 750px) {
  #register {
  height: auto;

    .box{
      width: 100%;
    }

    .p-responsive {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px !important;
    }
  }
}
</style>
