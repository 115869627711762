<template>
<div class="title-container">
  <h1 class="title-container__title">NOS NEWSLETTERS</h1>
</div>
</template>

<script>

export default {
}
</script>

<style scoped lang="scss">
@import "src/assets/_shared_scss/colors";

.title-container {
  display: flex;
  justify-content: center;
  background-color: $jaune;
  .title-container__title {
    color: white;
    padding: 3rem 0;
  }
}
</style>
