import Vue from 'vue'
import VueRouter from 'vue-router'

import Index from '../index.vue'
import Login from '../views/login.vue'
import Main from '../views/main.vue'
import Home from '../views/Home.vue'
import Register from '../views/register.vue'
import Committee from '../views/committee';
import CommitteeNewsletters from '../views/committee-newsletters';
import Article from '../views/article';
import Qsn from '../views/Qui_sommes_nous'
import Contact from '../views/contact.vue'
import Validated from '../views/validated'
import Asked from '../views/asked'
import Reset from '../views/recover_password'

import Admin from '../views/admin'
import AdminNewsletter from '../components/admin/newsletter'
import AdminPartners from '../components/admin/partners'
import AdminMeetings from '../components/admin/meetings'
import AdminMembers from '../components/admin/members'

import NotFound from '../views/404'

import store from "../store/index"
import Profile from '../views/profile'

Vue.use(VueRouter)

function isAuthenticated (to, from, next) {
  if (!store.getters.isFetched || store.getters.isAuthenticated) {
    next()
  } else {
    next({ name: 'login' })
  }
}

function isNotAuthenticated (to, from, next) {
  if (!store.getters.isFetched || !store.getters.isAuthenticated) {
    next()
  } else {
    next({ name: 'home' })
  }
}

function isAdmin (to, from, next) {
  if (!store.getters.isFetched || store.getters.isAdmin) {
    next()
  } else {
    next({ name: 'home' })
  }
}

const routes = [
  {
    path: '',
    redirect: { name: 'login' }
  },
  {
    path: '',
    component: Index,
    children: [
      {
        path: 'validated',
        name: 'validated',
        component: Validated
      },
      {
        path: 'asked',
        name: 'asked',
        component: Asked
      },
      {
        path: 'reset',
        name: 'reset',
        component: Reset
      },
      {
        path: 'register',
        name: 'register',
        component: Register
      },
      {
        path: 'login',
        name: 'login',
        component: Login,
        beforeEnter: isNotAuthenticated,
      },
      {
        path: '',
        name: 'main',
        component: Main,
        beforeEnter: isAuthenticated,
        children: [
          {
            path: 'home',
            name: 'home',
            component: Home
          },
          {
            path: 'auth/validate',
            name: 'Register',
            component: Register
          },
          {
            path: 'profile',
            name: 'profile',
            component: Profile
          },
          {
            path: 'qui-sommes-nous',
            name: 'Qsn',
            component: Qsn
          },
          {
            path: 'committee/:committeeId',
            name: 'committee',
            component: Committee,
            props: true
          },
          {
            path: 'contact',
            name: 'contact',
            component: Contact,
          },
          {
            path: 'committee/:committeeId/newsletters',
            name: 'committee-newsletters',
            component: CommitteeNewsletters,
            props: true
          },
          {
            path: 'articles/:articleId',
            name: 'article',
            component: Article,
            props: true
          },
          {
            path: 'admin',
            name: 'Admin',
            component: Admin,
            beforeEnter: isAdmin,
            children: [
              {
                path: 'newsletter',
                name: 'admin-newsletter',
                component: AdminNewsletter
              },
              {
                path: 'members',
                name: 'admin-members',
                component: AdminMembers
              },
              {
                path: 'partners',
                name: 'admin-partners',
                component: AdminPartners
              },
              {
                path: 'meetings',
                name: 'admin-meetings',
                component: AdminMeetings
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '*',
    name: '404',
    component: NotFound
  },

];


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
      }
    }
  }
});

export default router

