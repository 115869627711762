<template>
  <section class="columns is-multiline">
    <div class="column is-half is-full-mobile">
      <div class="box documents is-flex is-flex-direction-column is-justify-content-space-between m-5">
        <h4 class="subtitle is-4">
          Réunions passées et comptes-rendus
        </h4>
        <template v-if="pastMeetings.length">
          <div>
            <article v-for="meeting in pastMeetings" :key="meeting.id" class="media">
              <div class="media-content">
                <div class="content">
                  <p>
                    <strong>{{ meeting.title }}</strong>
                    <br>
                    <span>
                      le {{ moment(meeting.startAt).format('lll') }}
                    </span>
                    <br>
                    <a v-if="meeting.report" :href="meeting.report.path">
                      {{ meeting.report.filename }}
                    </a>
                  </p>
                </div>
              </div>
            </article>
          </div>
          <div>
            <modal_meetings_past :status="modal_status_past" :data="pastMeetings" @close="modal_status_past =  ''"/>
            <div class=" is-flex is-justify-content-center	is-align-items-center	">
              <button class="button is-pulled-right" @click="modal_status_past = 'is-active'">
                Voir plus
              </button>
            </div>
          </div>
        </template>
        <div v-else class="empty">
          <span class="icon is-large mb-5">
            <i class="fas fa-file"></i>
          </span>
          <h5 class="subtitle is-5">
            Il n'y a aucun document à venir
          </h5>
        </div>
      </div>
    </div>

    <div class="column is-half is-full-mobile">
      <div class="box meetings is-flex is-flex-direction-column is-justify-content-space-between m-5">
        <h4 class="subtitle is-4">
          Réunions à venir
        </h4>
        <template v-if="upcomingMeetings.length">
          <div>
            <article v-for="meeting in upcomingMeetings" :key="meeting.id" class="media">
              <div class="media-content">
                <div class="content">
                  <p>
                    <strong>{{ meeting.title }}</strong>
                    <br>
                    prévue le {{ moment(meeting.startAt).format('lll') }}
                  </p>
                </div>
              </div>
            </article>
          </div>
          <div>
            <modal_meetings_upcoming :status="modal_status_upcoming" :data="upcomingMeetings" @close="modal_status_upcoming =  ''"/>
            <div class=" is-flex is-justify-content-center	is-align-items-center	">
              <button class="button is-pulled-right" @click="modal_status_upcoming = 'is-active'">
                Voir plus
              </button>
            </div>
          </div>
        </template>
        <div v-else class="empty">
          <span class="icon is-large mb-5">
            <i class="fas fa-calendar"></i>
          </span>
          <h5 class="subtitle is-5">
            Il n'y a aucune réunion à venir
          </h5>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import moment from 'moment'


import modal_meetings_upcoming from "@/components/committee/modal_meetings_upcoming";
import modal_meetings_past from "@/components/committee/modal_meetings_past";

export default {
  name: 'cser-meetings',

  components: {
    modal_meetings_upcoming,
    modal_meetings_past
  },

  data () {
    return {
      moment,
      modal_status_past: '',
      modal_status_upcoming: ''
    }
  },

  props: {
    pastMeetings: {
      type: Array,
      required: true
    },
    upcomingMeetings: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/_shared_scss/colors";

$rotate1: 180deg;
$rotate2: 360deg;

section {
  margin-top: 5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  > div {
    .box {
      h4, h5, span.icon {
        text-align: center;
        color: white;
      }

      .empty {
        text-align: center;

        span.icon {
          font-size: xxx-large;
        }
      }

      .media {
        color: white;

        strong, a {
          color: white;
        }
      }

      &.documents {
        background-color: $rose;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          width: 120%;
          height: 120%;
          top: -1rem;
          right: -2rem;
          z-index: -1;
          background: url(~@/assets/images/svg/6.svg) 0 0 no-repeat;
          -webkit-transform: rotate($rotate1);
          -moz-transform: rotate($rotate1);
          -ms-transform: rotate($rotate1);
          -o-transform: rotate($rotate1);
          transform: rotate($rotate1);
        }
      }

      &.meetings {
        background-color: $bleu;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          width: 120%;
          height: 120%;
          top: -1rem;
          left: -2rem;
          z-index: -1;
          background: url(~@/assets/images/svg/6.svg) 0 0 no-repeat;
          -webkit-transform: rotate($rotate2);
          -moz-transform: rotate($rotate2);
          -ms-transform: rotate($rotate2);
          -o-transform: rotate($rotate2);
          transform: rotate($rotate2);
        }
      }
    }
  }
}
</style>
