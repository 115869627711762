<template src="./testimonial.html">
    
</template>

<script>
    export default {
        name: "Testimonial"
    }
</script>

<style lang="scss" scoped>
    @import "src/assets/_shared_scss/colors";
    @import "./testimonial.scss";
</style>
