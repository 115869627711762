<template>
  <div class="qsn">
    <Hero/>
    <Description/>
    <Redduce/>
  </div>
</template>

<script>

import Hero from "@/components/about/hero/hero";
import Description from "@/components/about/description/description";
import Redduce from "@/components/about/redduce/redduce";

export default {
  name: 'Qsn',
  components: {Redduce, Description, Hero},

}
</script>

<style scoped lang="scss">
.qsn {
  margin: 0 2rem;
}
</style>
