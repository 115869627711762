<template>
  <section class="newsletter">
    <titre></titre>
    <CommitteeNewsletterArticles
      v-if="articlesId"
      :articles="articles"
    />
  </section>
</template>

<script>
import titre from "../components/newsletters/title/title";
import CommitteeNewsletterArticles from "../components/newsletters/list/list";
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'committee-newsletters',

  components: {
    titre,
    CommitteeNewsletterArticles
  },

  props: {
    committeeId: {
      type: [Number, String],
      required: true
    }
  },

  data () {
    return {
      articlesId: null
    }
  },

  computed: {
    ...mapGetters(['getArticle']),
    articles () {
      return this.articlesId.map(articleId => this.getArticle(articleId))
    }
  },

  watch: {
    articlesId: {
      immediate: true,
      handler (articlesId) {
        if (!articlesId) {
          this.load()
        }
      }
    }
  },

  methods: {
    ...mapActions(['fetchCommitteeArticles']),
    async load () {
      this.loading = true
      this.error = null

      try {
        const { committeeId: id } = this
        this.articlesId = (await this.fetchCommitteeArticles({ id }))
            .map(article => article.id)
      } catch (error) {
        this.error = error.message
      }

      this.loading = false
    }
  }
}
</script>

<style scoped>

</style>
