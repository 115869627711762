<template>
  <div id="not-found" class="is-fullwidth">
    <section class="hero is-danger">
      <div class="hero-body">
        <p class="title">
          404, Not Found
        </p>
        <p class="subtitle">
          Vous avez essayé de rejoindre une page qui n'existe pas.
        </p>
      </div>
    </section>

    <div class="is-flex is-justify-content-center">
      <img src="~@/assets/images/cat.png" alt="hero">
    </div>
  </div>
</template>

<script>
export default {
  name: 'validated'
}
</script>

<style scoped lang="scss">
#not-found {
  flex: 1;
  height: 100vh;
  background-image: url('~@/assets/images/fond.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 10rem;

  img {
    margin-top: 5rem;
    height: 25rem;
  }
}
</style>
