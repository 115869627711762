<template>
  <section class="map">
    <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2623.2395127960012!2d2.396714551329091!3d48.89177240620791!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66c4694e670db%3A0xb75b0863a3e43bc5!2sAIDES%20(Si%C3%A8ge%20national)!5e0!3m2!1sfr!2sfr!4v1634239918414!5m2!1sfr!2sfr"
        width="100%" height="450" allowfullscreen="" loading="lazy"/>
  </section>
</template>

<script>
export default {
}
</script>

<style scoped lang="scss">
  .map {
    iframe {
      border: 0;
    }
  }
</style>
