<template>
  <section>
    <div class="overlay" @click="$emit('close')" />
    <form class="card" @submit.prevent.stop="submit">
      <header class="card-header">
        <p class="card-header-title">
          Nouvel article
        </p>
        <button class="card-header-icon" aria-label="more options">
          <span class="icon">
            <i class="fas fa-angle-down" aria-hidden="true"></i>
          </span>
        </button>
      </header>
      <div class="card-content">
        <div v-if="error" class="notification is-danger">
          <button class="delete" @click="error = null" />
          {{ error }}
        </div>

        <div class="content">
          <div class="field">
            <label class="label" for="title">Titre</label>
            <div class="control">
              <input
                  id="title"
                  v-model="title"
                  class="input"
                  type="text"
              >
            </div>
          </div>

          <div class="field">
            <label class="label" for="content">
              Contenu
            </label>
            <div class="control">
            <textarea
                id="content"
                v-model="content"
                class="textarea"
                type="text"
            />
            </div>
          </div>

          <div class="file has-name is-fullwidth">
            <label class="file-label">
              <input
                  class="file-input"
                  type="file"
                  name="resume"
                  accept="image/png,image/jpg,image/jpeg,image/bmp"
                  @change="attach"
              >
              <span class="file-cta">
                <span class="file-icon">
                  <i class="fas fa-upload"></i>
                </span>
                <span class="file-label">
                  Choisir une image
                </span>
              </span>
              <span class="file-name">
                {{ file && file.name }}
              </span>
            </label>
          </div>
        </div>
      </div>
      <footer class="card-footer buttons has-addons">
        <button class="card-footer-item button is-danger" @click="$emit('close')" type="button">
          Annuler
        </button>
        <button
            class="card-footer-item button is-success"
            :disabled="loading || !canSubmit"
            :class="{ 'is-loading is-disabled': loading }"
            type="submit"
        >
          {{ id ? 'Modifier' : 'Créer' }}
        </button>
      </footer>
    </form>
  </section>
</template>


<script>
import { mapActions } from 'vuex'

export default {
  name: 'modal-edit-newsletter',

  props: {
    article: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      error: null,
      loading: false,

      id: null,
      title: '',
      content: '',
      file: ''
    }
  },

  computed: {
    canSubmit () {
      return this.title && this.content && (this.file || this.id)
    }
  },

  watch: {
    article: {
      immediate: true,
      deep: true,
      handler (article) {
        if (article) {
          this.id = article.id
          this.title = article.title
          this.content = article.content
        }
      }
    }
  },

  methods: {
    ...mapActions(['uploadImage', 'createArticle', 'editArticle']),
    attach ({ target }) {
      this.file = target.files[0]
    },
    async submit () {
      this.loading = true

      try {
        let illustration
        if (!this.id || this.file) {
          const form = new FormData()
          form.append('file', this.file)
          illustration = await this.uploadImage({ form })
        }

        const { id, title, content } = this
        if (id) {
          await this.editArticle({ id, title, content, illustration: illustration && illustration.id })
        } else {
          await this.createArticle({ title, content, illustration: illustration.id })
        }

        this.$emit('close')
      } catch (error) {
        this.error = error.message
      }

      this.loading = false
    }
  }
}
</script>

<style scoped lang="scss">

section {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  border: solid 1px black;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;

  > .overlay {
    position: absolute;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
  }

  > form.card {
    z-index: 1000;
    width: 50rem;
  }
}

</style>

