import axios from 'axios'
import BaseService from './base'

export default class CommitteeService extends BaseService {
    getCommittees() {
        return axios.get(`${this.host}/committees`)
    }

    get_committee_id(id) {
        return axios.get(`${this.host}/committees/${id}/details`)
    }

    get_commission() {
        return axios.get(`${this.host}/commissions`)
    }
    getCommissionByCommitteId(id) {
        return axios.get(`${this.host}/committees/${id}/commissions`)
    }

    getCommissionId(id) {
        return axios.get(`${this.host}/commissions/${id}/members`)
    }

    getCommitteeArticles (id) {
        return axios.get(`${this.host}/committees/${id}/articles`)
    }

    getCommitteeMeetings (id) {
        return axios.get(`${this.host}/committees/${id}/meetings`)
    }

    getCommitteePartners (id) {
        return axios.get(`${this.host}/committees/${id}/partners`)
    }

    getCommitteeMembers (id) {
        return axios.get(`${this.host}/committees/${id}/members`)
    }

    getCommitteeElectedMembers (id) {
        return axios.get(`${this.host}/committees/${id}/members/elected`)
    }

    getCommitteeOfficeMembers (id) {
        return axios.get(`${this.host}/committees/${id}/members/office`)
    }

    contactCommittee (id, body) {
        return axios.post(`${this.host}/committees/${id}/contact`, body)
    }
    deleteArticle(id) {
        return axios.delete(`${this.host}/articles/${id}`)
    }
}
