<template>
  <footer class="footer">
    <div class="footer__grid">
      <div class="footer__grid__col footer__grid__col__description">
        <img src="~@/assets/logo.png" alt="logo"/>
        <span>
         Le CSE, votre garant pour une participation active des salariés aux décisions de l'entreprise
        </span>
      </div>
      <div class="footer__grid__col">
        <h4>NOS SERVICES</h4>
        <ul>
          <li>Qui sommes-nous ?</li>
          <li>ReducCe</li>
          <li>Commission</li>
        </ul>
      </div>
      <div class="footer__grid__col">
        <h4>NOUS CONTACTER</h4>
        <p>
          LADAPT, Tour Essor<br/>
          14 rue Scandicci<br/>
          93508 Pantin cedex<br/>
          Tél: 01.48.10.12.45
        </p>
      </div>
    </div>
    <div class="footer__mention">
      <span>Copyright LADAPT 2021. Tous droits réservés | Mentions légales</span>
    </div>
  </footer>
</template>

<script>
export default {
}
</script>

<style scoped lang="scss">
@import "src/assets/_shared_scss/colors";

.footer {
  padding: 0;
  width: 100%;

  &__grid {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 50px 0;
    background-color: $bleuclair;
    font-size: 1rem;
    &__col {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      h4 {
        padding: 0;
        margin: 0 0 20px 0;
        font-size: 1.5rem;
        font-weight: bolder;
        color: white;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          padding-left: 1em;
          color: white;
          text-indent: -.7em;
        }

        li::before {
          content: "- ";
        }
      }

      p {
        padding: 0;
        margin: 0;
        color: white;
      }
      &__description {
        flex-direction: row;

        img {
          margin-right: 20px;
          width: 150px;
          aspect-ratio: 1;
        }

        span {
          max-width: 30ch;
          text-align: justify;
          color: white;
          font-style: italic;
        }
      }
    }
    @media only screen and (max-width: 750px) {
      flex-direction: column;
      &__col {
        img, h4, ul, p {
          align-self: center;
          margin-bottom: 10px;
        }
        span {
          text-align: center;
          margin-bottom: 10px;
        }
        &__description {
          flex-direction: column;
        }
      }
    }
  }

  &__mention {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: $bleufonce;
    padding: 10px 0;
    span {
      text-align: center;
      color: white;
      font-size: 1rem;
      font-weight: bolder;
    }
  }
}
</style>
