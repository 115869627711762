import DocumentService from '../../services/document_service'

export default {
  state: {

  },

  getters: {

  },

  actions: {
    async uploadDocument (_, { file }) {
      const form = new FormData()
      form.append('file', file)

      const documentService = new DocumentService()
      const document = await documentService.uploadDocument(form)
      return document.data
    }
  },

  mutations: {

  }
}
