<template>
  <section>
    <section class="hero">
      <div class="hero-body">
        <p class="title">
          Membres
        </p>
        <p class="subtitle">
          Liste des membres de ce comité
        </p>
      </div>
    </section>

    <div v-if="error" class="notification is-danger mx-6 mt-5">
      <button class="delete" @click="error = null" />
      {{ error }}
    </div>

    <table class="table is-striped is-narrow is-hoverable mx-6 mb-6">
      <thead>
        <tr>
          <th>Nom</th>
          <th>Prénom</th>
          <th>Adresse e-mail</th>
          <th>Rôle</th>
          <th>Rôle interne</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="member of members" :key="member.id">
          <td>{{ member.lastname }}</td>
          <td>{{ member.firstname }}</td>
          <td>{{ member.email }}</td>
          <td>
            <select v-model="member.role" class="select" @change="submit(member)">
              <option
                  v-for="[key, value] in Object.entries(roles)"
                  :key="key"
                  :value="key"
              >
                {{ value }}
              </option>
            </select>
          </td>
          <td @click="editing = member.id">
            <input
                v-if="editing === member.id"
                v-model="member.profession"
                type="text"
                class="input is-small"
                v-focus
                @blur="submit(member)"
                @keydown="onKeyDown(member, $event.code)"
            >
            <span v-else>
              {{ member.profession }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'admin-members',

  data() {
    return {
      loading: false,
      loadings: {},

      members : [],
      error: null,

      editing: null,

      roles: {
        'super-admin': 'Super-Administrateur',
        'admin': 'Administrateur',
        'elected': 'Élu',
        'employee': 'Membre'
      }
    }
  },

  computed: {
    ...mapGetters(['getSelfCommittee'])
  },

  mounted () {
    this.load()
  },

  methods: {
    ...mapActions([
      'fetchCommitteeMembers',
      'editUser'
    ]),
    async load () {
      this.loading = true

      try {
        const id = this.getSelfCommittee
        this.members = await this.fetchCommitteeMembers({ id })
      } catch (error) {
        this.error = error.message
      }

      this.loading = false
    },

    async submit (member) {
      if (this.loadings[member.id]) return

      this.error = null
      this.loadings[member.id] = true

      try {
        await this.editUser({ ...member })
      } catch (error) {
        this.error = error.message
      }

      this.loadings[member.id] = false
      this.editing = null
    },

    onKeyDown (member, code) {
      if (code === 'Enter') {
        return this.submit(member)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

table tbody tr {
  height: 3rem;
  vertical-align: middle;

  cursor: text;

  td {
    vertical-align: middle;

    select {
      padding: 0;
    }
  }
}

</style>
