import Vue from 'vue'
import CommitteeService from '../../services/committee_service'

export default {
  state: {
    committees: {},
    commissions: {}
  },

  getters: {
    getCommittees: state => state.committees,
    getCommittee: state => id => state.committees[id.toString()],
    getCommission: state => state.commissions,
    getCommissionForCommitteeId: state => id => Object.values(state.commissions)
        .filter(commission => commission.committeeId === id)
  },

  actions: {
    async fetchCommittees ({ commit }) {
      const committeeService = new CommitteeService()
      const response = await committeeService.getCommittees()
      for (const datum of response.data) {
        commit('COMMITTEE_SET', datum)
      }
      return response.data
    },
    async loadCommittee ({ commit }, { id }) {
      const committeeService = new CommitteeService()
      const response = await committeeService.get_committee_id(id)
      commit('COMMITTEE_SET', response.data)
      return response.data
    },
    async fetchCommissionId({commit}, { id }) {
      const committeeService = new CommitteeService()
      const response = await committeeService.getCommissionByCommitteId(id)
      for (const datum of response.data) {
        commit('COMMISSION_SET', datum)
      }
      return response.data
    },
    async fetchCommissionMembers (_, { id }) {
      const committeeService = new CommitteeService()
      const response = await committeeService.getCommissionId(id)
      return response.data
    },

    async fetchCommitteeArticles ({ commit }, { id }) {
      const committeeService = new CommitteeService()
      const response = await committeeService.getCommitteeArticles(id)
      for (const datum of response.data) {
        commit('ARTICLE_SET', datum)
      }
      return response.data
    },
    async fetchCommitteeMeetings ({ commit }, { id }) {
      const committeeService = new CommitteeService()
      const response = await committeeService.getCommitteeMeetings(id)
      for (const datum of response.data) {
        commit('MEETING_SET', datum)
      }
      return response.data
    },
    async fetchCommitteePartners (_, { id }) {
      const committeeService = new CommitteeService()
      const response = await committeeService.getCommitteePartners(id)
      return response.data
    },
    async fetchCommitteeMembers (_, { id }) {
      const committeeService = new CommitteeService()
      const response = await committeeService.getCommitteeMembers(id)
      return response.data
    },
    async fetchCommitteeElectedMembers (_, { id }) {
      const committeeService = new CommitteeService()
      const response = await committeeService.getCommitteeElectedMembers(id)
      return response.data
    },
    async fetchCommitteeOfficeMembers (_, { id }) {
      const committeeService = new CommitteeService()
      const response = await committeeService.getCommitteeOfficeMembers(id)
      return response.data
    },
    async contactCommittee (_, { id, name, email, content }) {
      const committeeService = new CommitteeService()
      await committeeService.contactCommittee(id, { name, email, content })
    }
  },

  mutations: {
    COMMITTEE_SET (state, committee) {
      Vue.set(state.committees, committee.id.toString(), committee)
    },
    COMMISSION_SET(state, commission) {
      Vue.set(state.commissions, commission.id.toString(), commission)
    }
  }
}
