<template>
  <section>
    <div class="columns is-multiline m-5">
      <div v-for="article in articles" :key="article.id" class="column is-one-quarter">
        <ArticleCard :article="article" />
      </div>
    </div>
  </section>
</template>

<script>

import ArticleCard from '../../cards/article-card'

export default {
  name: 'committee-newsletters-list',

  components: {
    ArticleCard
  },

  props: {
    articles: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">

</style>

