<template>
  <section v-if="article">
    <Title :title="article.title"/>
    <section class="info">
      <Description :content="article.content" :illustration="article.illustration"/>
      <Author/>
    </section>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import Title from '@/components/article/title';
import Description from '@/components/article/description';
import Author from '@/components/article/author';

export default {
  name: 'article',

  components: {Author, Description, Title},

  props: {
    articleId: {
      type: [Number, String],
      required: true
    }
  },

  computed: {
    ...mapGetters(['getArticle']),
    article () {
      return this.getArticle(this.articleId)
    }
  },

  watch: {
    article: {
      immediate: true,
      handler (article) {
        if (!article) {
          this.load()
        }
      }
    }
  },

  methods: {
    ...mapActions(['fetchArticle']),
    async load () {
      this.loading = false
      this.error = null

      try {
        const { articleId: id } = this
        await this.fetchArticle({ id })
      } catch (error) {
        this.error = error.message
      }

      this.loading = false
    }
  }
}
</script>

<style scoped lang="scss">

</style>
