import AuthService from '../../services/auth_service'
import Vue from 'vue'

export default {
  state: {
    fetched: !JSON.parse(localStorage.getItem('token')),
    accessToken: JSON.parse(localStorage.getItem('token')),
  },
  getters : {
    isFetched: state => state.fetched,
    isAuthenticated: (state, getters) => getters.isFetched ? !!(state.accessToken && getters.getSelf) : null,
    getToken: state => state.accessToken
  },
  actions: {
    async loginWithCredentials ({ commit, dispatch }, { email, password }) {
      const authService = new AuthService()
      const response = await authService.loginWithCredentials({ email, password })
      commit('setToken', response.data)
      await dispatch('fetchSelf')
    },
    async askAccount (_, { email, committee }) {
      const authService = new AuthService()
      await authService.askAccount({ email, committee })
    },
    async register (_, { avatar, birthdate, commission, email, isCentralCommitteeMember, firstname, lastname, password, token, profession, role, professionCentralCommittee, professionalEmail }) {
      const authService = new AuthService()
      await authService.register({ avatar, birthdate, commission, email, isCentralCommitteeMember, firstname, lastname, password, token, profession, role, professionCentralCommittee, professionalEmail }, { Authorization: `Bearer ${token}` })
    },

    async forgotPassword (_, {email}) {
      const authService = new AuthService();
      await authService.forgotPassword({ email })
    },

    async recoverPassword ({ commit, dispatch }, { password, token }) {
      const authService = new AuthService();
      const response = await authService.recoverPassword({password}, { Authorization: `Bearer ${token}` });
      commit('setToken', response.data);
      await dispatch('fetchSelf')

    },

    async logout ({ commit, dispatch }) {
      dispatch('closeSidebar')
      commit('deleteToken')
      commit('deleteSelf')
      location.reload()
    }
  },

  mutations: {
    setFetched (state, { fetched }) {
      Vue.set(state, 'fetched', fetched)
    },
    setToken (state, accessToken) {
      state.accessToken = accessToken
      localStorage.setItem('token', JSON.stringify(accessToken))
    },
    deleteToken (state) {
      state.accessToken = null
      localStorage.removeItem('token')
    }
  }
}
