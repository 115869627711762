import axios from 'axios'
import BaseService from './base'

export default class MeetingService extends BaseService {
    createMeeting (body) {
        return axios.post(`${this.host}/meetings/`, body)
    }

    editMeeting (id, body) {
        return axios.put(`${this.host}/meetings/${id}/`, body)
    }

    attachDocument (id, documentId) {
        return axios.post(`${this.host}/meetings/${id}/attach/${documentId}/`)
    }

    deleteMeeting(id) {
        return axios.delete(`${this.host}/meetings/${id}`)
    }
}
