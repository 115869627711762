<template>
  <section>
    <div class="overlay" @click="$emit('close')" />
    <form class="content card" @submit.stop.prevent="submit">
      <header class="card-header">
        <p class="card-header-title">
          Nouveau partenaire
        </p>
        <button class="card-header-icon" aria-label="more options">
          <span class="icon">
            <i class="fas fa-angle-down" aria-hidden="true"></i>
          </span>
        </button>
      </header>
      <div class="card-content">
        <div class="content">
          <div class="field">
            <label class="label" for="title">Nom</label>
            <div class="control">
              <input
                  id="title"
                  v-model="name"
                  class="input"
                  type="text"
              >
            </div>
          </div>

          <div class="file has-name is-boxed is-fullwidth is-centered">
            <label class="file-label">
              <input
                  class="file-input"
                  type="file"
                  name="resume"
                  @change="attach"
              >
              <span class="file-cta">
                <span class="file-icon">
                  <i class="fas fa-upload"></i>
                </span>
                <img v-if="preview" :src="preview" />
                <span v-else class="file-label text-center">
                  Choisissez un logo
                </span>
              </span>
              <span v-if="file" class="file-name text-center">
                {{ file.name }}
              </span>
            </label>
          </div>
        </div>
      </div>
      <footer class="card-footer buttons has-addons">
        <button class="card-footer-item button is-danger" @click="$emit('close')" type="button">
          Annuler
        </button>
        <button
            class="card-footer-item button is-success"
            :disabled="loading"
            :class="{ 'is-loading is-disabled': loading }"
            type="submit"
        >
          {{ id ? 'Modifier' : 'Créer' }}
        </button>
      </footer>
    </form>
  </section>
</template>


<script>
import { mapActions } from 'vuex'

export default {
  name: 'modal-edit-partner',

  data() {
    return {
      loading: false,

      id: null,
      name: '',
      file: null,
      preview: null
    }
  },

  methods: {
    ...mapActions(['uploadImage', 'createPartner', 'editPartner']),
    attach ({ target }) {
      this.file = target.files[0]

      const reader = new FileReader();
      reader.addEventListener('load', (event) => {
        this.preview = event.target.result
      })
      reader.readAsDataURL(this.file)
    },
    async submit () {
      this.loading = true

      try {
        const form = new FormData()
        form.append('file', this.file)
        const logo = await this.uploadImage({ form })

        const { name } = this
        await this.createPartner({ name, logo: logo.id })

        this.$emit('close')
      } catch (error) {
        console.error(error)
        // todo gérer l'erreur
      }

      this.loading = false
    }
  }
}
</script>

<style scoped lang="scss">

section {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  border: solid 1px black;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;

  > .overlay {
    position: absolute;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
  }

  > form.card {
    z-index: 1000;

    width: 20rem;
  }

  .container_modal{
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80%;

    input, textarea{
      width: 20%;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      padding: 5px;

      &:focus-visible{
        border: 1px solid red;
        border-radius: 0.25rem;
      }
    }
  }
}

</style>

