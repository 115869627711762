<template>
  <nav class="navbar">
    <div class="navbar-brand">
      <img src="~@/assets/logo.png" alt=""/>
    </div>

    <div class="navbar-menu">
      <div class="navbar-start is-align-items-center">
        <router-link class="navbar-item" to="/home">
          Accueil
        </router-link>

        <div class="divider" />

        <router-link class="navbar-item" to="/qui-sommes-nous">
          Qui sommes-nous ?
        </router-link>

        <div class="divider" />

        <template v-if="nationalCommittee">
          <router-link class="navbar-item" :to="{ name: 'committee', params: { committeeId: nationalCommittee.id } }">
            CSE C
          </router-link>

          <div class="divider" />
        </template>

        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link">
            CSE R
          </a>

          <div class="navbar-dropdown">
            <router-link
                v-for="committee in regionalCommittees"
                :key="committee.id"
                :to="{ name: 'committee', params: { committeeId: committee.id }}"
                class="navbar-item"
            >
              {{ committee.name }}
            </router-link>
          </div>
        </div>

        <div class="divider" />

        <router-link class="navbar-item" :to="{ name: 'contact'}">
          Nous contacter
        </router-link>
      </div>
    </div>
  </nav>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'main-navigation',

  data() {
    return {
      committees: []
    }
  },

  computed: {
    ...mapGetters(['getCommittee', 'getSelfCommittee', 'isEmployee']),
    nationalCommittee () {
      return this.committees.find(committee => committee.type === 'csec')
    },
    regionalCommittees () {
      return this.committees.filter(committee => committee.type === 'cser')
    }
  },

  mounted() {
    this.load()
  },

  methods: {
    ...mapActions(['fetchCommittees']),
    async load () {
      try {
        this.committees = await this.fetchCommittees()
      } catch (error) {
        console.error(error)
      }
    }
  },
}

</script>

<style lang="scss" scoped>
nav.navbar {
  margin: 0 5rem;

  @media screen and (max-width: 769px) {
    margin: 0 1rem;
  }

  .navbar-brand img {
    height: 6rem;
    width: 6rem;

    @media screen and (max-width: 769px) {
      display: none;
    }
  }

  .navbar-menu {
    .navbar-start {
      margin-right: unset;
      margin-left: auto;

      a:hover, a:active {
        background-color: transparent;
      }

      .divider {
        width: .3rem;
        height: 1.5rem;
        align-self: center;
        background-color: #FDD247;
      }
    }
  }
}

@import "../assets/_shared_scss/colors";
</style>
