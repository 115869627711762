<template>
  <div v-if="!isFetched" class="app__loading">
    <div class="app__loading_spinner"></div>
  </div>
  <router-view v-else />
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

import Header from "./components/navigation"
import TopNav from "./components/header"
import Footer from "./components/footer/footer"

export default {
  name: 'loading',

  components: {
    Footer,
    Header,
    TopNav
  },

  computed: {
    ...mapGetters(['isFetched', 'isAuthenticated'])
  },

  watch: {
    isFetched: {
      immediate: true,
      handler (isFetched) {
        if (!isFetched) {
          this.fetch()
        }
      }
    },
    isAuthenticated: {
      handler (isAuthenticated) {
        if (this.oldRoute) {
          if (this.oldRoute.name !== this.$route.name) {
            this.oldRoute = null
            this.$router.push(this.location)
          }
        } else if (isAuthenticated === true && this.$route.name !== 'home') {
          this.$router.push({ name: 'home' })
        } else if (isAuthenticated === false && this.$route.name !== 'login') {
          this.$router.push({ name: 'login' })
        }
      }
    }
  },

  methods: {
    ...mapActions(['fetchSelf']),
    async fetch () {
      try {
        if (!['home', 'login'].includes(this.$route.name)) {
          this.oldRoute = this.$route
        }

        await this.fetchSelf()
      } catch (error) {
        // Todo: gérer l'erreur
      }
    }
  }
}
</script>


<style lang="scss">
@import "assets/_shared_scss/colors";

body {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .app__loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.5);

    .app__loading_spinner {
      width: 50px;
      height: 50px;
      background-color: $bleunoir;
      animation-name: spin;
      animation-duration: 5000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      @keyframes spin {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    }
  }
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
