<template>
  <div class="modal" :class="status">
    <div class="modal-background"></div>
    <div class="modal-content box">
      <h1>Membres de la commission </h1>
      <div v-for="c in data" :key="c.id">
      <p>{{c}}</p>
      </div>
      <button class="button is-pulled-right" @click="$emit('close')"> Fermer</button>
    </div>
    <button class="modal-close is-large" aria-label="close" @click="$emit('close')"></button>
  </div>
</template>

<script>


export default {
  name: "modal_list_membres",
  props : ['status', 'data']
}
</script>

<style scoped>

</style>