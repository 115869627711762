<template>
  <section>
    <div class="overlay" @click="$emit('close')" />
    <form class="content card" @submit.prevent.stop="submit">
      <header class="card-header">
        <p class="card-header-title">
          Programmer une réunion
        </p>
      </header>
      <div class="card-content">
        <div class="content">
          <div v-if="error" class="notification is-danger">
            <button class="delete" @click="error = null" />
            {{ error }}
          </div>

          <div class="field">
            <label class="label" for="title">Titre</label>
            <div class="control">
              <input
                  id="title"
                  v-model="title"
                  class="input"
                  type="text"
              >
            </div>
          </div>

          <div class="field">
            <label class="label" for="content">
              Date de la réunion
            </label>
            <div class="control">
            <input
                id="content"
                v-model="startAt"
                class="input"
                type="datetime-local"
            />
            </div>
          </div>
        </div>
      </div>
      <footer class="card-footer buttons has-addons">
        <button class="card-footer-item button is-danger" @click="$emit('close')" type="button">
          Annuler
        </button>
        <button
            class="card-footer-item button is-success"
            :disabled="loading"
            :class="{ 'is-loading is-disabled': loading }"
            type="submit"
        >
          {{ id ? 'Modifier' : 'Créer' }}
        </button>
      </footer>
    </form>
  </section>
</template>


<script>
import { mapActions } from 'vuex'

export default {
  name: 'modal-edit-newsletter',

  props: {
    meeting: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      loading: false,
      error: null,

      id: null,
      title: '',
      startAt: null
    }
  },

  watch: {
    meeting: {
      immediate: true,
      deep: true,
      handler (meeting) {
        if (meeting) {
          this.id = meeting.id
          this.title = meeting.title
          this.startAt = meeting.startAt && meeting.startAt.toLocaleString().slice(0, -8)
        }
      }
    }
  },

  methods: {
    ...mapActions(['createMeeting', 'editMeeting']),
    async submit () {
      this.loading = true

      try {
        const { id, title, startAt } = this
        if (id) {
          await this.editMeeting({ id, title, startAt })
        } else {
          await this.createMeeting({ title, startAt })
        }

        this.$emit('close')
      } catch (error) {
        this.error = error.message
      }

      this.loading = false
    }
  }
}
</script>

<style scoped lang="scss">

section {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  border: solid 1px black;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;

  > .overlay {
    position: absolute;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
  }

  > form.card {
    z-index: 1000;
    min-width: 25rem;
  }
}

</style>

