<template>
  <div v-if="committee" class="cser">
    <Title :title="committee.name" />
    <Members
        v-if="elected"
        :members="elected"
        :show-profession="committee.type === 'cser'"
        :show-profession-central-committee="committee.type === 'csec'"
        title="Les membres élus"
    />
    <Members
        v-if="office && office.length"
        :members="office"
        :show-profession="committee.type === 'cser'"
        :show-profession-central-committee="committee.type === 'csec'"
        title="Les membres du bureau"
    />
    <Newsletters
        v-if="committee.lastArticles"
        :committee-id="committeeId"
        :articles="committee.lastArticles"
    />

    <Meetings
        v-if="committee.upcomingMeetings && committee.pastMeetings && (isAdmin || isElected) && (getSelfCommittee == committee.id || committee.id == 1) "
        :upcomingMeetings="committee.upcomingMeetings"
        :pastMeetings="committee.pastMeetings"
    />
<!--    <Commission :id_cse="committee.id"/>-->
    <Partners v-if="committee.partners" :partners="committee.partners"/>
    <Contact :committee="committee" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import Title from '@/components/committee/title';
import Members from '@/components/committee/members';
import Newsletters from '@/components/committee/newsletters';
import Meetings from '@/components/committee/meetings';
import Partners from '@/components/committee/partners';
import Contact from '@/components/committee/contact';
import Commission from "@/components/about/commission/commission";

export default {
  name: 'committee',

  data() {
    return {
      loading: false,
      articles: [],
      officeMembers: null,
      electedMembers: null
    }
  },

  components: {
    Contact,
    Members,
    Meetings,
    Newsletters,
    Partners,
    Title,
    Commission
  },

  props: {
    committeeId: {
      type: [Number, String],
      required: true
    }
  },

  computed: {
    ...mapGetters(['getCommittee', 'getSelfCommittee', 'isAdmin', 'isElected']),
    committee () {
      return this.getCommittee(this.committeeId)
    },
    office () {
      return this.officeMembers || this.committee.officeMembers
    },
    elected () {
      return this.electedMembers || this.committee.electedMembers
    }
  },

  watch: {
    committeeId () {
      this.article = []
      this.officeMembers = null
      this.electedMembers = null
    },
    committee: {
      immediate: true,
      handler (committee) {
        if (!committee || !committee.partners) {
          this.load()
        }

        if (!this.officeMembers && !this.electedMembers) {
          this.loadMembers()
        }
      }
    }
  },

  methods: {
    ...mapActions([
      'loadCommittee',
      'fetchCommitteeOfficeMembers',
      'fetchCommitteeElectedMembers'
    ]),
    async load () {
      this.loading = true

      try {
        await this.loadCommittee({ id: this.committeeId })
      } catch (error) {
        this.error = error
      }

      this.loading = false
    },
    async loadMembers () {
      this.loading = true

      try {
        const { committeeId: id } = this
        this.officeMembers = await this.fetchCommitteeOfficeMembers({ id })
        this.electedMembers = await this.fetchCommitteeElectedMembers({ id })
      } catch (error) {
        this.error = error
      }

      this.loading = false
    }
  },
}
</script>

<style scoped lang="scss">

</style>
