<template>
  <div class="description is-flex is-justify-content-center">
    <div>
      <img
          v-if="illustration"
          :src="illustration.path"
          :alt="illustration.filename"
      >
      <p>
        {{ content }}
      </p>
    </div>
  </div>
</template>

<script>
export default {

  props: {
    content: {
      type: String,
      required: true,
    },
    illustration: {
      type: Object,
      nullable: true
    }
  }
}
</script>

<style scoped lang="scss">
.description {
  width: 100%;

  > div {
    max-width: 60rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
      object-fit: cover;
      max-height: 350px;
      aspect-ratio: 1;
    }
    p{
      overflow-wrap: anywhere;
      margin-top: 5%;
    }
  }
}
</style>
