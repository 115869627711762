<template>
  <nav class="navbar" role="navigation">
    <div class="navbar-brand">
      <h1 class="navbar-item">
        <span class="navbar-burger" @click="toggleSidebar">
          <i class="fas fa-bars fa-2x"></i>
        </span>

        Bonjour {{ firstname }} !
      </h1>
    </div>

    <div class="navbar-menu">
      <div class="navbar-start">
        <router-link class="navbar-item" :to="{ name: 'profile' }">
          Mon profil
        </router-link>

        <a class="navbar-item" v-on:click="logout">Déconnexion</a>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'main-header',

  computed: {
    ...mapGetters(['getSelf']),
    self () {
      return this.getSelf
    },
    firstname () {
      return this.self && this.self.firstname
    },
    role () {
      return this.self.role
    }
  },
  methods: {
    ...mapActions(['logout', 'toggleSidebar'])
  }
}

</script>

<style lang="scss" scoped>

nav.navbar {
  background-color: #b82677;
  padding: 0 5rem;
  height: 3rem;

  @media screen and (max-width: 769px) {
    padding: 0 1rem;
  }

  .navbar-brand .navbar-item {
    color: white;
    font-weight: bold;

    .navbar-burger {
      color: white;
      height: unset;
    }
  }

  .navbar-menu .navbar-start {
    margin-right: unset;
    margin-left: auto;

    .navbar-item {
      color: white;

      &:hover {
        background-color: #b82677;
      }
    }
  }
}

</style>
