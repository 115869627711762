<template src="./login_form.html" />

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'login-form',

  data () {
    return {
      loading: false,
      error: null,

      email: '',
      password: '',
      committee: '',
      create_account: false,
      forgot_password: false
    }
  },

  computed: {
    ...mapGetters(['getCommittees']),
    committees () {
      return Object.values(this.getCommittees).filter(c => c.type === 'cser')
    },
    canSubmit () {
      return this.email && (this.create_account ? this.committee : this.password)
    }
  },

  watch: {
    committees: {
      immediate: true,
      handler (committees) {
        if (!committees || !committees.length) {
          this.loadCommittees()
        }
      }
    }
  },

  methods: {
    ...mapActions(['fetchCommittees', 'loginWithCredentials', 'askAccount', 'forgotPassword']),
    async loadCommittees () {
      try {
        await this.fetchCommittees()
      } catch (error) {
        this.error = error.message
      }
    },
    submit () {
      if (this.create_account) {
        return this.checkEmail()
      } else if (this.forgot_password) {
        this.forgot()
      } else {
        return this.login()
      }
    },
    async login () {
      this.loading = true

      try {
        const { email, password } = this
        await this.loginWithCredentials({ email, password })
      } catch (error) {
        this.error = error.message
      }

      this.loading = false
    },

    checkEmail () {
      const email = this.email.split('@')
      if (process.env.NODE_ENV === 'development' || email[1] === 'ladapt.net' || email[1] === 'epitech.eu') {
        this.ask()
      } else {
        this.$swal('l\'email doit contenir @ladapt.net ')
      }
    },

    async forgot () {
      try {
        const { email } = this
        await this.forgotPassword({ email })
        location.reload()
      } catch (error) {
        this.error = error.message
      }
    },
    async ask () {
      this.loading = true

      try {
        const { email, committee } = this
        await this.askAccount({ email, committee })
        await this.$router.push({ name: 'asked' })
      } catch (error) {
        this.error = error.message
      }

      this.loading = false
    }
  }
}

</script>

<style lang="scss">
@import "../../assets/_shared_scss/colors";
@import "login_form";

h2 {
  text-align: center;
}

select {
  padding: 10px;
}
</style>
