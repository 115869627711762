<template src="./services.html">
    
</template>

<script>
    export default {
        name: "Services"
    }
</script>

<style lang="scss" scoped>
    @import "src/assets/_shared_scss/colors";
    @import "./services.scss";
</style>
