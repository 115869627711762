import Vue from 'vue'
import PartnerService from '../../services/partners_service'

export default {
  state: {
    partners: {}
  },

  getters: {
    getPartner: state => id => state.partners[id.toString()]
  },

  actions: {
    async createPartner ({ commit }, { name, logo }) {
      const partnerService = new PartnerService()
      const response = await partnerService.createPartner({ name, logo })
      commit('PARTNER_SET', response.data)
      return response.data
    },
    async deletePartner ({ commit }, { id }) {
      const partnerService = new PartnerService()
      await partnerService.deletePartner(id)
      commit('PARTNER_DEL', { id })
    }
  },

  mutations: {
    PARTNER_SET (state, partner) {
      Vue.set(state.partners, partner.id.toString(), partner)
    },
    PARTNER_DEL (state, { id }) {
      Vue.delete(state.partners, id)
    }
  }
}
