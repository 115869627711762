<template>
  <div>
    <h1 class="title is-1">
      {{ title }}
    </h1>
  </div>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/_shared_scss/colors";

div {
  background-color: $jaune;

  h1 {
    text-align: center;
    padding: 1rem;
    color: white;
  }
}
</style>
