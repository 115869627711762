import Vue from 'vue'
import axios from 'axios'
import AuthService from '../../services/auth_service'
import UserService from '../../services/user_service'

export default {
  state: {
    self: null
  },

  getters : {
    isAdmin: state => state.self && ['admin', 'super-admin'].includes(state.self.role),
    isEmployee: state => state.self && state.self.role === 'employee',
    isElected:  state => state.self && state.self.role === 'elected',
    getSelf: state => state.self,
    getSelfCommittee: state => state.self.committee.id,
    getUser: state => {
      return state.userInfos;
    }
  },

  actions: {
    async fetchSelf ({ commit }) {
      const authService = new AuthService()
      try {
        const response = await authService.fetchSelf()
        commit('setSelf', response.data)
      } finally {
        commit('setFetched', { fetched: true })
      }
    },
    user: ({commit}) => {
      return new Promise((resolve, reject) => {
        axios.get('https://'+`${process.env.VUE_APP_API}`+'/users/self')
          .then(function (res) {
            commit('setUser', res.data);
            resolve(res);
          })
          .catch(function (error) {
            reject(error);
          })

      })
    },
    async editProfile ({ commit }, { avatar }) {
      const authService = new AuthService()
      const response = await authService.editProfile({ avatar })
      commit('setSelf', response.data)
    },
    async editUser (_, { id, isCentralCommitteeMember, profession, role }) {
      const userService = new UserService()
      const response = await userService.edit(id, { isCentralCommitteeMember, profession, role })
      return response.data
    }
  },

  mutations: {
    setSelf (state, self) {
      Vue.set(state, 'self', self)
    },
    deleteSelf (state) {
      Vue.delete(state, 'self')
    }
  }
}
