import ImageService from '../../services/image_service'

export default {
  state: {

  },

  getters: {

  },

  actions: {
    async uploadImage (_, { form }) {
      const imageService = new ImageService()
      const image = await imageService.uploadImage(form)
      return image.data
    }
  },

  mutations: {

  }
}
