<template>
  <div class="card article">
    <div class="card-image">
      <figure class="image is-2by1">
        <img v-if="article.illustration" :src="article.illustration.path" :alt="article.illustration.filename">
        <img v-else src="https://creazilla-store.fra1.digitaloceanspaces.com/cliparts/1722917/newsletter-clipart-md.png" alt="letter">
      </figure>
    </div>
    <div class="card-content">
      <div class="media">
        <div class="media-content">
          <p class="title is-4">
            {{ article.title }}
          </p>
          <p class="subtitle is-6">
            {{ article.content }}
          </p>
        </div>
      </div>
    </div>
    <footer class="card-footer p-3 is-flex is-justify-content-center	is-align-items-center ">
      <router-link :to="{ name: 'article', params: { articleId: article.id }}">
        EN SAVOIR +
      </router-link>
    </footer>
  </div>
</template>

<script>

export default {
  name: 'meeting-card',

  props: {
    article: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/_shared_scss/colors";

.card {
  figure {
    overflow: hidden;

    img {
      display: block;
      width: auto;
      height: auto;
    }
  }

  .media-content {
    overflow: hidden;

    p.subtitle {
      white-space: nowrap;
      overflow: hidden;
      display: block;
      text-overflow: ellipsis;
    }
  }

  .card-footer {
    border: none;
    a {
      text-decoration: none;
      background-color: white;
      border: solid 1px $rose;
      color: $rose;
      padding: .5rem;
      font-weight: bold;
    }
  }
}
</style>
