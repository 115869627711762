<template>
  <div id="sidebar">
    <div class="bar" :class="{ open }">
      <div class="content">
        <h5>
          Bonjour {{ firstname }} !
        </h5>

        <router-link class="navbar-item" to="/home">
          Accueil
        </router-link>

        <router-link class="navbar-item" to="/qui-sommes-nous">
          Qui sommes-nous ?
        </router-link>

        <template v-if="nationalCommittee">
          <router-link class="navbar-item" :to="{ name: 'committee', params: { committeeId: nationalCommittee.id } }">
            CSE C
          </router-link>

        </template>

        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link" @click="menu = !menu">
            CSE R
          </a>

          <div class="navbar-dropdown" :class="{ open: menu }">
            <router-link
                v-for="committee in regionalCommittees"
                :key="committee.id"
                :to="{ name: 'committee', params: { committeeId: committee.id }}"
                class="navbar-item"
            >
              {{ committee.name }}
            </router-link>
          </div>
        </div>

        <router-link class="navbar-item" :to="{ name: 'contact'}">
          Nous contacter
        </router-link>

        <template v-if="isAdmin">
          <h5 class="mt-5">
            Admin |
            <span v-if="self.committee">
              {{ self.committee.name }}
            </span>
          </h5>

          <router-link class="navbar-item" :to="{ name: 'admin-newsletter'}">
            Newsletter
          </router-link>
          <router-link class="navbar-item" :to="{ name: 'admin-meetings'}">
            Réunions
          </router-link>
          <router-link class="navbar-item" :to="{ name: 'admin-members'}">
            Membres
          </router-link>
          <router-link class="navbar-item" :to="{ name: 'admin-partners'}">
            Partenaires
          </router-link>
        </template>

        <router-link class="navbar-item mt-5" :to="{ name: 'profile' }">
          Mon profil
        </router-link>

        <a class="navbar-item is-bold mt-5" v-on:click="logout">
          Déconnexion
        </a>
      </div>
    </div>
    <div class="overlay" :class="{ open }" @click="closeSidebar" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'sidebar',

  data () {
    return {
      menu: false
    }
  },

  computed: {
    ...mapGetters(['isSidebarOpen', 'getSelf', 'getCommittees', 'isAdmin']),
    open () {
      return this.isSidebarOpen
    },
    self () {
      return this.getSelf
    },
    firstname () {
      return this.self && this.self.firstname
    },
    role () {
      return this.self.role
    },
    committees () {
      return Object.values(this.getCommittees)
    },
    nationalCommittee () {
      return this.committees.find(committee => committee.type === 'csec')
    },
    regionalCommittees () {
      return this.committees.filter(committee => committee.type === 'cser')
    }
  },

  methods: {
    ...mapActions(['closeSidebar', 'logout'])
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/_shared_scss/colors";

#sidebar {
  .bar {
    z-index: 1000;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 15rem;
    transform: translateX(-100%);
    background-color: $bleu;
    transition: transform .3s ease-in-out;

    .content {
      padding: 1rem 0;

      h5 {
        color: white;
        padding-left: .75rem;
      }



      a {
        color: white;

        &:focus, &.router-link-active, &:hover {
          background-color: white;
          color: $bleu;
        }
      }

      .navbar-link::after {
        color: white
      }

      .navbar-dropdown {
        padding: 0;
        height: 0;
        overflow: hidden;
        transition: height .3s ease;

        &.open {
          height: auto;
        }
      }
    }

    &.open {
      transform: translateX(0);
    }
  }

  .overlay {
    z-index: 900;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(black, .5);
    transform: translateX(-100%);

    &.open {
      transform: translateX(0);
    }
  }
}

</style>
