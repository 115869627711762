<template>
  <section v-if="partners.length">
    <h3 class="subtitle is-3"><strong>N</strong>os partenaires</h3>
    <div class="columns mx-5">
      <img
        class="column is-one-quarter p-5"
        v-for="partner of partners"
        :key="partner.id"
        :src="partner.logo.path"
        alt="logo"
      >
    </div>
  </section>
</template>

<script>
export default {
  name: 'cser-partners',

  props: {
    partners: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/_shared_scss/colors";

section {
  margin-top: 5rem;

  h3 {
    text-align: center;

    strong {
      color: $rose;
      font-size: 3rem;
    }
  }
}
</style>
