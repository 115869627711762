<template>
  <section>
    <ModalEditNewsletter
        v-if="editing"
        :meeting="editing"
        @close="editing = false"
    />

    <section class="hero">
      <div class="hero-body">
        <p class="title">
          Réunions à venir
        </p>
      </div>
      <div class="level-right" style="float: right">
        <button class="button is-success mr-6" @click="editing = {}">
          <font-awesome-icon class="icon_create mr-3" icon="plus"/> Programmer une réunion
        </button>
      </div>
    </section>

    <div v-if="error" class="notification is-danger mx-6 mt-5">
      <button class="delete" @click="error = null" />
      {{ error }}
    </div>

    <div class="columns is-multiline m-5">
      <div v-for="meeting in upcomingMeetings" :key="meeting.id" class="column is-3">
        <MeetingCard :meeting="meeting" @error="error = $event" @edit="editing = meeting" />
      </div>
    </div>

    <section class="hero">
      <div class="hero-body pb-0">
        <p class="title">
          Réunions passées
        </p>
      </div>
    </section>

    <div class="columns is-multiline m-5">
      <div v-for="meeting in pastMeetings" :key="meeting.id" class="column is-3">
        <MeetingCard :meeting="meeting" @error="error = $event" />
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import moment from 'moment'

import ModalEditNewsletter from "./modal-edit-meeting";
import MeetingCard from '../../cards/meeting-card'

export default {
  name: 'newsletter-admin',

  components : {
    ModalEditNewsletter,
    MeetingCard
  },

  data() {
    return {
      moment,

      meetingsId : [],

      creating: false,
      editing: null,

      loading: false,
      loadingAttach: null,
      error: null
    }
  },

  computed: {
    ...mapGetters(['getSelfCommittee', 'getMeeting']),
    meetings () {
      return this.meetingsId.map(meetingId => this.getMeeting(meetingId))
    },
    upcomingMeetings () {
      return this.meetings
          .filter(meeting => new Date(meeting.startAt) >= new Date())
          .sort((a, b) => new Date(a.startAt) - new Date(b.startAt))
    },
    pastMeetings () {
      return this.meetings
          .filter(meeting => new Date(meeting.startAt) < new Date())
          .sort((a, b) => new Date(a.startAt) - new Date(b.startAt))
    }
  },

  watch: {
    creating: 'load',
    editing: 'load'
  },

  mounted () {
    this.load()
  },

  methods: {
    ...mapActions(['fetchCommitteeMeetings']),
    async load () {
      this.loading = true

      try {
        const id = this.getSelfCommittee
        this.meetingsId = (await this.fetchCommitteeMeetings({ id }))
          .map(meeting => meeting.id)
      } catch (error) {
        this.error = error.message
      }

      this.loading = false
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/_shared_scss/colors";
.admin_newsletter_container{
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
}
.admin_newsletter{
  display: flex;
  justify-content: center;
  align-content: center;
  h2{
    text-align: center;
  }
  .icon_create{
    font-size: 30px;
    border: solid 1px $bleufonce;
    color: $bleuclair;
    padding: 10px;
    border-radius: 30px;
  }
  .box_newsletter{
    width: 33%;
    margin: 10px;
    padding: 10px;
    text-align: center;
    box-shadow: 0 0 10px 2px #ccc;
    .container_button{
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      b{
        border: solid 1px black;
        padding: 10px;
      }
    }
  }
}

.card {
  figure img {
    background-color: rgba(gray, .1);
  }
}
</style>

