<template>
  <section>
    <div class="overlay" @click="$emit('close')" />
    <div class="content card">
      <div class="card-image">
        <figure class="image is-2by1">
          <img :src="article.illustration.path" alt="Placeholder image">
        </figure>
      </div>
      <div class="card-content">
        <div class="media">
          <div class="media-content">
            <p class="title is-4">{{ article.title }}</p>
            <p class="subtitle is-6">Créé le <time datetime="2016-1-1">{{ createdAt }}</time></p>
          </div>
        </div>

        <div class="content">
          {{ article.content }}
        </div>
      </div>
    </div>
  </section>
</template>


<script>
import moment from 'moment'

export default {
  name: 'modal-read-newsletter',

  props: {
    article: {
      type: Object,
      default: null
    }
  },

  computed: {
    createdAt () {
      return moment(this.article.createdAt).format('lll')
    }
  }
}
</script>

<style scoped lang="scss">

section {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  border: solid 1px black;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 1000;

  > .overlay {
    position: absolute;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
  }

  > div.card {
    figure {
      margin: 0;
    }

    z-index: 1000;
    width: 50rem;
  }
}

</style>

