export default {
    state: {
        isLoading: false,
    },
    mutations: {
        setLoadingTrue: function (state) {
            state.isLoading = true;
        },
        setLoadingFalse: function (state) {
            setTimeout(() => {state.isLoading = false}, 1000);
        },
    },
    getters: {
        getLoading: state => {
            return state.isLoading;
        }
    }
}
