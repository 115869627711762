<template>
  <section class="hero">
    <img src="~@/assets/images/slider/qui_sommes_nous.png" alt="hero">
    <hr/>
  </section>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
@import "src/assets/_shared_scss/colors";

.hero {
  img {
    width: 100%;
  }
  hr {
    margin-top: 3rem;
    margin-bottom: 3rem;
    width: 90%;
    height: 2px;
    border: none;
    background-color: $rose;
  }
}
</style>
