<template>
  <section class="mt-5">
    <h3 class="subtitle is-3"><strong>{{ title.slice(0, 1) }}</strong>{{ title.slice(1) }}</h3>
    <div v-if="members.length" class="members is-relative is-flex is-flex-direction-column is-align-items-center">
      <span v-if="_members.length > 4 && index > 0" class="icon mx-3 is-hidden-tablet is-inline-mobile" @click="slideLeft">
        <i class="fas fa-long-arrow-alt-up fa-2x"></i>
      </span>

      <div class="members is-relative is-flex is-flex-direction-row is-align-items-center is-justify-content-center">
        <span v-if="_members.length > 4 && index > 0" class="icon mx-3 is-hidden-mobile" @click="slideLeft">
          <i class="fas fa-long-arrow-alt-left fa-2x"></i>
        </span>

        <div class="is-flex-grow-1 is-flex-wrap-wrap is-flex is-flex-direction-row">
          <div v-for="(member, index) in slicedMembers" :key="index" class="column is-one-quarter-desktop is-full-mobile is-half-tablet">
            <UserCard
                :user="member"
                :show-profession="showProfession"
                :show-profession-central-committee="showProfessionCentralCommittee"
            />
          </div>
        </div>

        <span v-if="_members.length > 4 && index < _members.length - 4" class="icon mx-3 is-hidden-mobile" @click="slideRight">
          <i class="fas fa-long-arrow-alt-right fa-2x"></i>
        </span>
      </div>

      <span v-if="_members.length > 4 && index < _members.length - 4" class="icon mx-3 is-hidden-tablet is-inline-mobile" @click="slideRight">
        <i class="fas fa-long-arrow-alt-down fa-2x"></i>
      </span>
    </div>

    <div v-else class="is-flex is-flex-direction-column is-align-items-center">
      <span class="icon m-3" @click="slideRight">
        <i class="fa fa-user fa-2x"></i>
      </span>
      <h5 class="subtitle is-5">Il n'y a aucun membre dans ce comité</h5>
    </div>
  </section>
</template>

<script>
import UserCard from '../cards/user-card'

export default {
  name: 'committee-members',

  components: {
    UserCard
  },

  props: {
    members: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    showProfession: {
      type: Boolean,
      default: false
    },
    showProfessionCentralCommittee: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      index: 0
    }
  },

  computed: {
    _members () {
      return this.members
    },
    slicedMembers () {
      return this._members.slice(this.index, this.index + 4)
    }
  },

  methods: {
    slideLeft () {
      this.index -= 1
    },
    slideRight () {
      this.index += 1
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/_shared_scss/colors";

section {
  h3 {
    text-align: center;

    strong {
      color: $rose;
      font-size: 3rem;
    }
  }

  .members {
    width: 100%;

    span.icon {
      font-size: larger;
    }
  }
}
</style>
