<template>
  <div id="app">
    <router-view/>
  </div>
</template>


<script>
import Header from "./components/navigation"
import TopNav from "./components/header"
import Footer from "./components/footer/footer"

export default {
  name: 'app',

  components: {
    Footer,
    Header,
    TopNav
  }
}
</script>


<style lang="scss">
@import "assets/_shared_scss/colors";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;

  .app__loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.5);

    .app__loading_spinner {
      width: 50px;
      height: 50px;
      background-color: $bleunoir;
      animation-name: spin;
      animation-duration: 5000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      @keyframes spin {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    }
  }
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
