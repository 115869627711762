<template>
  <section>
    <ModalEditNewsletter
        v-if="editing"
        :article="editing"
        @close="editing = false"
    />

    <section class="hero">
      <div class="hero-body">
        <p class="title">
          Partenaires
        </p>
        <p class="subtitle">
          Liste des partenaires affichés
        </p>
      </div>
      <div class="level-right" style="float: right">
        <button class="button is-success mr-6" @click="editing = {}">
          <font-awesome-icon class="icon_create mr-3" icon="plus"/> Ajouter un partenaire
        </button>
      </div>
    </section>

    <div v-if="error" class="notification is-danger mx-6 mt-5">
      <button class="delete" @click="error = null" />
      {{ error }}
    </div>

    <div class="columns is-multiline mx-5 mb-5">
      <div v-for="partner in partners" :key="partner.id" class="column is-3">
        <div class="card">
          <div class="card-image">
            <figure class="image is-2by1">
              <img class="p-5" :src="partner.logo.path" alt="Placeholder image">
            </figure>
          </div>
          <div class="card-content">
            <div class="media">
              <div class="media-content">
                <p class="title is-4">{{ partner.name }}</p>
              </div>
            </div>
          </div>
          <footer class="card-footer">
            <a class="card-footer-item button is-danger" :class="{ 'is-loading': loadingRemove === partner.id }" @click="remove(partner.id)">
              Supprimer
            </a>
          </footer>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import ModalEditNewsletter from "./modal-edit-partner";

export default {
  name: 'newsletter-admin',

  components : {
    ModalEditNewsletter
  },

  data() {
    return {
      loadingRemove: false,
      error: null,

      partners : [],

      creating: false,
      editing: null,
      reading: null
    }
  },

  computed: {
    ...mapGetters(['getSelfCommittee'])
  },

  watch: {
    creating: 'load',
    editing: 'load'
  },

  mounted () {
    this.load()
  },

  methods: {
    ...mapActions([
        'fetchCommitteePartners',
        'deletePartner'
    ]),
    async load () {
      this.loading = true

      try {
        const id = this.getSelfCommittee
        this.partners = await this.fetchCommitteePartners({ id })
      } catch (error) {
        console.error(error)
        // ignore error
      }

      this.loading = false
    },
    async remove (id) {
      this.loadingRemove = id

      try {
        await this.deletePartner({ id })
        this.partners = this.partners.filter(partner => partner.id !== id)
      } catch (error) {
        this.error = error.message
        // ignore error
      }

      this.loadingRemove = null
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/_shared_scss/colors";
.admin_newsletter_container{
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
}
.admin_newsletter{
  display: flex;
  justify-content: center;
  align-content: center;
  h2{
    text-align: center;
  }
  .icon_create{
    font-size: 30px;
    border: solid 1px $bleufonce;
    color: $bleuclair;
    padding: 10px;
    border-radius: 30px;
  }
  .box_newsletter{
    width: 33%;
    margin: 10px;
    padding: 10px;
    text-align: center;
    box-shadow: 0 0 10px 2px #ccc;
    .container_button{
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      b{
        border: solid 1px black;
        padding: 10px;
      }
    }
  }
}

.card {
  figure img {
    background-color: rgba(gray, .1);
  }
}
</style>

