<template>
    <section class="slider">
        <div class="slider__position">
            <img class="slider__img" src="~@/assets/images/slider/slider.png" alt="slider">
        </div>
        <div class="slider__line">
            <span class="slider__line__style"></span>
        </div>
    </section>
</template>

<script>
    export default {
        name: "Slider"
    }
</script>

<style lang="scss" scoped>
    @import "src/assets/_shared_scss/colors";
    .slider{
        &__position {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            .slider__img{
                width: 100%;
            }
        }
        &__line{
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 5%;
            &__style{
              width: 90%;
              height: 2px;
              background-color: $rose;
            }
        }
    }
</style>
