<template>
  <section id="commission" class="container_commission">
    <hr/>
    <h1><span>L</span>iste des commissions {{id_cse}} </h1>

    <div v-for="c in commissions" :key="c.id" class="card" style="width: 50%;">
      <div class="card-content">
        <div class="media">
          <div class="media-left">
          </div>
          <div class="media-content">
            <p class="title is-4">{{c.name}}</p>
            <p class="subtitle is-6">Lorem ipsum dolor sit amet</p>
          </div>
        </div>
        <div class="content">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Phasellus nec iaculis mauris.
          <br>
          <time datetime="2016-1-1">11:09 PM - 1 Jan 2016</time>
        </div>
        <footer class="card-footer">
          <button class="presentation__button" @click="commissionId(c.id)">Voir les membres</button>
        </footer>
      </div>
    </div>

    <ModalListMembres :status="modal_status" :data="membres_commission"  @close="modal_status =  ''"/>
  </section>


</template>

<script>


import {mapActions, mapGetters} from "vuex";
import ModalListMembres from "./modal-list-membres";

export default {
  name: "commission",
  data () {
    return {
      modal_status: '',
      membres_commission: []
    }
  },

  components: {
    ModalListMembres
  },

  props: ['id_cse'],

  computed: {
    ...mapGetters([
      'getCommissionForCommitteeId'
    ]),
    commissions() {
      return this.getCommissionForCommitteeId(this.id_cse)
    }
  },

  watch: {
    id_cse () {
      this.load()
    }
  },

  methods: {
    ...mapActions([
      'fetchCommissionId',
      'fetchCommissionMembers'
    ]),
    async load() {
      const id = this.$props.id_cse;
      await this.fetchCommissionId({ id });
    },
    async commissionId(id) {
      this.membres_commission = [];
      const res = await this.fetchCommissionMembers({ id });
      this.membres_commission.push(res);
      this.modal_status = 'is-active';
    }
  },
  mounted () {
    this.load()
  }

}
</script>

<style scoped lang="scss">
@import "src/assets/_shared_scss/colors";

.container_commission {
  display: flex;
  flex-direction: column;
  align-items: center;

  hr {
    margin-top: 3rem;
    margin-bottom: 3rem;
    width: 90%;
    height: 2px;
    border: none;
    background-color: $rose;
  }

  .card {
    margin: 10px;
  }

  h1 {
    color: $noir;
    font-weight: bolder;
    text-align: center;
    font-size: 2rem;
    margin-bottom: 2rem;

    span {
      font-size: 2.5rem;
      color: $rose;
    }
  }
  .card-footer{
    display: flex;
    justify-content: center;
    align-content: center;
    padding-top: 1.5rem;
    .presentation__button {
      text-decoration: none;
      background-color: white;
      text-align: center;
      border: solid 1px $rose;
      width: 150px;
      color: $rose;
      padding: 15px;
      font-weight: bold;
      &:hover{
        cursor: pointer;
      }
    }
  }
}
</style>