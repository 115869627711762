var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"services"},[_vm._m(0),_c('div',{staticClass:"services__container"},[_c('div',{staticClass:"services__container__bandeau services__container__bandeau--color"}),_c('div',{staticClass:"services__container__row"},[_c('div',{staticClass:"services__container__box"},[_c('div',{staticClass:"services__container__box__content"},[_c('span'),_c('h4',[_vm._v("Qui sommes-nous?")]),_c('p',[_vm._v(" Le Comité social et économique (CSE) est un organisme créé pour garantir la participation des salariés aux décisions relatives à la gestion et à l'évolution économique de l'entreprise. ")]),_c('router-link',{attrs:{"to":{ path: '/qui-sommes-nous',hash: '#qsn'}}},[_vm._v(" EN SAVOIR + ")]),_c('span')],1),_vm._m(1)]),_c('div',{staticClass:"services__container__box"},[_c('div',{staticClass:"services__container__box__content"},[_c('span'),_c('h4',[_vm._v("ReducCE")]),_c('p',[_vm._v(" ReducCe est un site de billetterie en ligne exclusivement destiné aux comités d’entreprises ")]),_c('router-link',{attrs:{"to":{
                        path: '/qui-sommes-nous#reduc',
                        hash: '#reduc'
                     }}},[_vm._v(" EN SAVOIR + ")]),_c('span')],1),_vm._m(2)]),_c('div',{staticClass:"services__container__box"},[_c('div',{staticClass:"services__container__box__content"},[_c('span'),_c('h4',[_vm._v("COMMISSION")]),_c('p',[_vm._v(" Les commissions d'un CSE sont des sous-groupes qui traitent des sujets spécifiques liés aux missions du CSE ")]),_c('router-link',{attrs:{"to":{
                        path: '/qui-sommes-nous',
                        hash: '#commission'
                    }}},[_vm._v(" EN SAVOIR + ")]),_c('span')],1),_vm._m(3)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"services__content__title my-5"},[_c('h3',{staticClass:"title"},[_c('span',[_vm._v("N")]),_vm._v("os services")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"services__container__box--color"},[_c('h4',{staticClass:"services__container__box__title"},[_vm._v("QUI SOMMES-NOUS?")]),_c('div',{staticClass:"services__container__content"},[_c('span'),_c('img',{attrs:{"src":require("@/assets/images/home/nos_services/profil.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"services__container__box--color"},[_c('h4',{staticClass:"services__container__box__title"},[_vm._v("ReducCE")]),_c('div',{staticClass:"services__container__content"},[_c('span'),_c('img',{attrs:{"src":require("@/assets/images/home/nos_services/profil.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"services__container__box--color"},[_c('h4',{staticClass:"services__container__box__title"},[_vm._v("COMMISSION")]),_c('div',{staticClass:"services__container__content"},[_c('span'),_c('img',{attrs:{"src":require("@/assets/images/home/nos_services/profil.png"),"alt":""}})])])
}]

export { render, staticRenderFns }