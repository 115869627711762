<template>
  <section>
    <ModalEditNewsletter
        v-if="editing"
        :article="editing"
        @close="editing = false"
    />

    <ModalReadNewsletter
        v-if="reading"
        :article="reading"
        @close="reading = false"
    />

    <section class="hero">
      <div class="hero-body">
        <p class="title">
          Newsletters
        </p>
        <p class="subtitle">
          Liste des articles
        </p>
      </div>
      <div class="level-right" style="float: right">
        <button class="button is-success mr-6" @click="editing = {}">
          <font-awesome-icon class="icon_create mr-3" icon="plus"/> Nouvel article
        </button>
      </div>
    </section>

    <div class="columns is-multiline m-5">
      <div v-for="article in articles" :key="article.id" class="column is-3">
        <div class="card">
          <div class="card-image">
            <figure class="image is-2by1">
              <img :src="article.illustration.path" alt="Placeholder image">
            </figure>
          </div>
          <div class="card-content">
            <div class="media">
              <div class="media-content">
                <p class="title is-4">{{ article.title }}</p>
                <p class="subtitle is-6">Créé le <time datetime="2016-1-1">{{ moment(article.createdAt).format('lll') }}</time></p>
              </div>
            </div>
          </div>
          <footer class="card-footer">
            <button class="card-footer-item button" @click="editing = article">
              Éditer
            </button>
            <button class="card-footer-item button" @click="reading = article">
              Visualiser
            </button>
            <button class="card-footer-item button is-danger" @click="delete_articles(article.id)">
              Supprimer
            </button>
          </footer>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import moment from 'moment'

import ModalEditNewsletter from "./modal-edit-newsletter";
import ModalReadNewsletter from "./modal-read-newsletter";

export default {
  name: 'newsletter-admin',

  components : {
    ModalEditNewsletter,
    ModalReadNewsletter
  },

  data() {
    return {
      moment,

      articles : [],

      creating: false,
      editing: null,
      reading: null
    }
  },

  computed: {
    ...mapGetters(['getSelfCommittee'])
  },

  watch: {
    editing () {
      this.load()
    }
  },

  mounted () {
    this.load()
  },

  methods: {
    ...mapActions(['fetchCommitteeArticles', 'deleteArticle']),
    async load () {
      this.loading = true

      try {
        const id = this.getSelfCommittee
        this.articles = await this.fetchCommitteeArticles({ id })
      } catch (error) {
        // ignore error
      }

      this.loading = false
    },

    async delete_articles(id) {
      const res = await this.deleteArticle({id})
      if(res.status == 200) {
        this.load()
      }

    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/_shared_scss/colors";
.admin_newsletter_container{
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
}
.admin_newsletter{
  display: flex;
  justify-content: center;
  align-content: center;
  h2{
    text-align: center;
  }
  .icon_create{
    font-size: 30px;
    border: solid 1px $bleufonce;
    color: $bleuclair;
    padding: 10px;
    border-radius: 30px;
  }
  .box_newsletter{
    width: 33%;
    margin: 10px;
    padding: 10px;
    text-align: center;
    box-shadow: 0 0 10px 2px #ccc;
    .container_button{
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      b{
        border: solid 1px black;
        padding: 10px;
      }
    }
  }
}

.card {
  figure img {
    background-color: rgba(gray, .1);
  }
}
</style>

