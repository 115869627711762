<template>
  <section class="title">
    <h1>{{this.title}}</h1>
  </section>
</template>

<script>
export default {
  props: ['title'],
}
</script>

<style scoped lang="scss">
@import "../../assets/_shared_scss/colors";

.title {
  background-color: $jaune;
  width: 100%;
  padding: 2rem 0;
  h1 {
    text-transform: uppercase;
    color: white;
    text-align: center;
  }
}
</style>
