import Vue from 'vue'
import MeetingService from '../../services/meeting_service'

export default {
  state: {
    meetings: {}
  },

  getters: {
    getMeeting: state => id => state.meetings[id.toString()]
  },

  actions: {
    async createMeeting ({ commit, dispatch }, { title, startAt }) {
      const meetingService = new MeetingService()
      const response = await meetingService.createMeeting({ title, startAt })
      dispatch('loadCommittee', { id: response.data.committee.id })
      commit('MEETING_SET', response.data)
      return response
    },
    async editMeeting ({ commit, dispatch }, { id, title, startAt }) {
      const meetingService = new MeetingService()
      const response = await meetingService.editMeeting(id, { title, startAt })
      dispatch('loadCommittee', { id: response.data.committee.id })
      commit('MEETING_SET', response.data)
      return response
    },
    async attachDocument ({ commit, dispatch }, { id, documentId }) {
      const meetingService = new MeetingService()
      const response = await meetingService.attachDocument(id, documentId)
      dispatch('loadCommittee', { id: response.data.committee.id })
      commit('MEETING_SET', response.data)
      return response
    },
    async deleteMeeting({ dispatch }, {id}) {
      const meetingService = new MeetingService()
      const response = await meetingService.deleteMeeting(id)
      dispatch('loadCommittee', { id: response.data.committee.id })
      return response
}
  },

  mutations: {
    MEETING_SET (state, meeting) {
      Vue.set(state.meetings, meeting.id.toString(), meeting)
    }
  }
}
