<template>
    <div class="forgot">
        <form class="box" @submit.stop.prevent="change_password">
            <div v-if="error" class="notification is-danger">
                <button class="delete" @click="error = null" />
                {{ error }}
            </div>
            <h1 class="title is-3">
                réinitialisation mot de passe
            </h1>

            <div class="columns is-multiline">
                <div class="field column is-12">
                    <label class="label">
                       Nouveau mot de passe
                    </label>
                    <div class="control">
                        <input v-model="password" class="input" type="password">
                    </div>
                </div>
                <div class="field column is-12">
                    <label class="label">
                        Confirmation du nouveau mot de passe
                    </label>
                    <div class="control">
                        <input v-model="c_password" class="input" type="password">
                    </div>
                </div>
            </div>

            <button
                    type="submit"
                    class="button container_form__button"
            >
                Changer mon mot de passe
            </button>
        </form>
    </div>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        name: "recover_password",
        data() {
            return {
                password: '',
                c_password: '',
                error: null,
            }
        },
        methods: {
            ...mapActions(['recoverPassword']),

            async change_password() {
                if(this.password === this.c_password)
                {
                    try {
                    const token = this.$route.query.token;
                    const {password} = this;

                    await this.recoverPassword({password, token})
                    } catch (error) {
                        this.error = error.message
                    }
                }
                else{
                    this.error = "Le mot de passe n'est pas identique"
                }
            }
        },

    }
</script>

<style scoped>

    body{
        margin: 0
    }
    .forgot{
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url('~@/assets/images/fond.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        height: 100vh;
    }

    form.box {
        max-width: 40rem;
    }


</style>
