<template>
  <div id="main">
    <Sidebar />
    <div>
      <TopNav />
      <AdminNavigation v-if="isAdmin" />
      <Header />
    </div>
    <router-view/>
    <Footer/>
  </div>
</template>


<script>
import { mapGetters } from 'vuex'

import Sidebar from "./sidebar"
import TopNav from "../components/header"
import AdminNavigation from "../components/admin/navigation"
import Header from "../components/navigation"
import Footer from "../components/footer/footer"

export default {
  name: 'app-main',

  components: {
    Sidebar,
    Header,
    TopNav,
    AdminNavigation,
    Footer
  },

  computed: {
    ...mapGetters(['isAdmin', 'isSidebarOpen'])
  }
}
</script>

<style scoped>

#main {
  overflow-y: auto;
  overflow-x: hidden;
}
body{
  margin: 0
}
.container_login{
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('~@/assets/images/fond.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}
</style>
