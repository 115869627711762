import ReducceService from '../../services/reducce_service'

export default {
  state: {

  },

  getters: {

  },

  actions: {
    async fetchRedirection () {
      const reducceService = new ReducceService()
      const response = await reducceService.redirect()
      return response.data
    }
  },

  mutations: {

  }
}
