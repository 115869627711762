import axios from 'axios'
import BaseService from './base'

export default class PartnersService extends BaseService {
    createPartner (body) {
        return axios.post(`${this.host}/partners/`, body)
    }

    deletePartner (id) {
        return axios.delete(`${this.host}/partners/${id}/`)
    }
}
