<template>
  <div class="contact">
    <Map/>
    <Form/>
  </div>
</template>

<script>

import Map from '@/components/contact/map';
import Form from '@/components/contact/form';

export default {
  name: 'contact',

  components: {
    Map,
    Form
  },
}
</script>

<style scoped lang="scss">

</style>
