<template>
  <section class="contact">
    <h3 class="subtitle is-3">Nous contacter</h3>
    <div class="columns">
      <div class="column is-half px-5">
        <div class="box">
          <h4>{{ committee.name }}</h4>
          <div v-for="admin in committee.admins" :key="admin.id">
            <p>{{ admin.firstname }} {{ admin.lastname }}</p>
          </div>
          <div class="contact__grid__col-horaires">
            <p>Horaires:</p>
            <p>Ouvert du lundi au vendredi</p>
            <p>9h00 – 18h00</p>
          </div>
        </div>
      </div>
      <form class="column is-half" @submit.stop.prevent="submit">
        <div v-if="error" class="column notification is-danger">
          <button class="delete" @click="error = null" />
          {{ error }}
        </div>

        <div v-if="success" class="column notification is-success">
          <button class="delete" @click="success = null" />
          {{ success }}
        </div>

        <div class="columns is-multiline">
          <div class="column is-half field">
            <label class="label">
              Adresse e-mail
            </label>
            <div class="control">
              <input
                  v-model="email"
                  class="input"
                  type="text"
              >
            </div>
          </div>

          <div class="column is-half field">
            <label class="label">
              Nom
            </label>
            <div class="control">
              <input
                  v-model="name"
                  class="input"
                  type="text"
              >
            </div>
          </div>

          <div class="column field">
            <label class="label">
              Votre message
            </label>
            <div class="control">
              <textarea
                  v-model="content"
                  class="textarea"
                  type="text"
              />
            </div>
          </div>
        </div>

        <button
            class="button is-pulled-right"
            :class="{ 'is-loading': loading }"
            :disabled="loading"
            type="submit"
        >
          Envoyer
        </button>
      </form>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'cser-contact',

  props: {
    committee: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      name: '',
      email: '',
      content: '',

      loading: false,
      error: null,
      success: null
    }
  },

  computed: {
    ...mapGetters(['getSelf']),
    self () {
      return this.getSelf
    }
  },

  watch: {
    self: {
      immediate: true,
      deep: true,
      handler (self) {
        if (self) {
          this.name = `${self.firstname} ${self.lastname}`
          this.email = self.email
        }
      }
    }
  },

  methods: {
    ...mapActions(['contactCommittee']),
    async submit () {
      this.loading = true
      this.error = null

      try {
        const { email, name, content } = this
        await this.contactCommittee({ id: this.committee.id, email, name, content })
        this.success = 'Votre message à bien été envoyé, vous serez recontacté via l\'adresse e-mail indiquée'
      } catch (error) {
        this.error = error.message
      }

      this.loading = false
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/_shared_scss/colors";

section.contact {
  margin-top: 5rem;
  background-color: $rose;
  position: relative;
  padding: 2rem;

  .column .box {
    margin-top: 2rem;
  }

  :before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: -50px;
    left: -50px;
    z-index: -1;
    background: url(~@/assets/images/svg/6.svg) 0 0 no-repeat;
  }
  :after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: -50px;
    right: -50px;
    z-index: -1;
    background: url(~@/assets/images/svg/4.svg) right no-repeat;
  }

  h3 {
    text-align: center;
    color: white;
  }

  label.label {
    color: white;
  }
}
</style>
