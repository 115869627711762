<template>
  <section>
    <div class="columns">
      <div class="column is-two-fifths">
        <img src="~@/assets/logo.png" alt="logo"/>
        <div>
          <b><font-awesome-icon icon="map-marker-alt"/> Adresse : </b>
          <p>14 rue Scandicci</p>
          <p>93508 Pantin</p>
          <p>France</p>
        </div>
        <div>
          <b><font-awesome-icon icon="phone-alt"/> Téléphone :</b>
          <p>01.00.00.00.00</p>
        </div>
        <div>
          <b><font-awesome-icon icon="envelope"/> Email</b>
          <p>contact@ladapt.net</p>
        </div>
        <div>
          <b><font-awesome-icon icon="clock"/> Horaires:</b>
          <p>Ouvert du Lundi au Vendredi</p>
          <p>9h00 - 18h00 (Fermé le Week-end)</p>
        </div>
      </div>

      <div class="column is-three-fifths">
        <form @submit.prevent.stop="submit">
          <div v-if="error" class="notification is-danger">
            <button class="delete" @click="error = null" />
            {{ error }}
          </div>

          <div v-if="success" class="notification is-success">
            <button class="delete" @click="success = null" />
            {{ success }}
          </div>

          <div class="columns is-multiline">
            <div class="column is-full">
              <div class="field">
                <label class="label">Comité</label>
                <div class="control">
                  <div class="select is-fullwidth">
                    <select v-model="committee">
                      <option v-for="committee in committees" :key="committee.id" :value="committee.id">
                        {{ committee.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-half">
              <div class="field">
                <label class="label">Email</label>
                <div class="control">
                  <input
                      v-model="email"
                      class="input"
                      type="text"
                  >
                </div>
              </div>
            </div>
            <div class="column is-half">
              <div class="field">
                <label class="label">Nom</label>
                <div class="control">
                  <input
                      v-model="name"
                      class="input"
                      type="text"
                  >
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <label class="label">Message</label>
                <div class="control">
                <textarea
                    v-model="content"
                    class="textarea"
                    type="text"
                />
                </div>
              </div>
            </div>
          </div>

          <div class="field is-grouped is-pulled-right">
            <div class="control">
              <button class="button is-link" :class="{ 'is-loading': loading }" type="submit">
                Envoyer
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      loading: false,
      error: null,
      success: null,

      committee: '',
      email: '',
      name: '',
      content: '',
    }
  },

  computed: {
    ...mapGetters(['getSelf', 'getCommittees']),
    self () {
      return this.getSelf
    },
    committees () {
      return this.getCommittees
    }
  },

  watch: {
    self: {
      immediate: true,
      deep: true,
      handler (self) {
        this.name = `${self.firstname} ${self.lastname}`
        this.email = self.email
        this.committee = self.committee.id
      }
    }
  },

  methods: {
    ...mapActions(['contactCommittee']),
    async submit () {
      this.loading = true

      try {
        const { committee: id, email, name, content } = this
        await this.contactCommittee({ id, email, name, content })
        this.success = 'Votre message à bien été envoyé, vous serez recontacté via l\'adresse e-mail indiquée'
      } catch (error) {
        this.error = error.message
      }

      this.loading = false
    }
  }
}
</script>

<style scoped lang="scss">
section {
  margin-top: 5rem;
  margin-bottom: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  > div.columns {
    max-width: 60rem;
  }
}

.form {
  display: flex;
  margin: 50px auto;
  max-width: 900px;
  font-family: Montserrat, 'Trebuchet MS', Helvetica, sans-serif;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
  color: #6b6b6b;
  font-size: 14px;

  &__col-left {
    margin-left: 10px;

    img {
      width: 150px;
      height: 150px;
    }

    div {
      b {
        font-style: italic;
      }

      p {
        margin: 0;
      }
    }
  }

  &__col-right {
    flex: 1;
    display: flex;
    flex-direction: column;

    &__input-box {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 10px;

      div {
        display: flex;
        flex-direction: column;
        padding: 0 15px;
        width: 50%;

        label {
        }

        input {
          color: #909090;
          padding: 12px 10px;
          background-color: #f5f6f8;
          border: 1px solid #e9e9e9;

          &:focus {
            outline: none;
          }
        }
      }
    }

    &__textarea-box {
      display: flex;
      flex-direction: column;
      padding: 0 15px;

      label {
      }

      textarea {
        color: #909090;
        padding: 12px 10px;
        background-color: #f5f6f8;
        border: 1px solid #e9e9e9;

        &:focus {
          outline: none;
        }
      }
    }

    &__button-box {
      padding: 0 15px;
      margin-top: 10px;
      input[type="submit"] {
        transition: 0.5s;
        padding: 10px 20px;
        border: 1px solid white;
        border-radius: 0;
        color: white;
        background-color: #093463;
        text-transform: uppercase;
        &:hover {
          border: 1px solid #093463;
          cursor: pointer;
          color: #093463;
          background-color: white;
        }
      }
    }
  }

  @media only screen and (max-width: 750px) {
    flex-direction: column;
    margin: 0 15px;
    &__col-left {
      width: 100%;
      margin-left: 0;
    }

    &__col-right {
      width: 100%;

      &__input-box {
        flex-direction: column;

        div {
          width: 100%;
          padding: 0;

          input {

          }
        }
      }

      &__textarea-box {
        padding: 0;

        textarea {

        }
      }
    }
  }
}
</style>
