import Vue from 'vue'
import Vuex from 'vuex'
import auth from "./modules/auth"
import user from "./modules/user"
import articles from "./modules/articles"
import committees from "./modules/committees"
import meetings from "./modules/meetings"
import images from "./modules/images"
import partners from "./modules/partners"
import document from "./modules/document"
import reducce from "./modules/reducce"
import loading from "@/store/modules/loading"

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        sidebar: false
    },

    getters: {
        isSidebarOpen: state => state.sidebar
    },

    actions: {
        async toggleSidebar ({ commit, getters }) {
            commit('SIDEBAR_OPEN_SET', { open: !getters.isSidebarOpen })
        },
        async closeSidebar ({ commit }) {
            commit('SIDEBAR_OPEN_SET', { open: false })
        },
    },

    mutations: {
        SIDEBAR_OPEN_SET (state, { open }) {
            Vue.set(state, 'sidebar', open)
        }
    },

    modules: {
        auth,
        user,
        loading,
        committees,
        meetings,
        images,
        articles,
        partners,
        document,
        reducce
    }
});
