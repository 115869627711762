<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <h1 class="navbar-item">
        Administration <span v-if="committee" class="ml-1">| {{ committee.name }}</span>
      </h1>
    </div>

    <div class="navbar-menu">
      <div class="navbar-start">
        <router-link class="navbar-item" :to="{ name: 'admin-newsletter'}">Newsletter</router-link>
        <router-link class="navbar-item" :to="{ name: 'admin-meetings'}">Réunions</router-link>
        <router-link class="navbar-item" :to="{ name: 'admin-members'}">Membres</router-link>
        <router-link class="navbar-item" :to="{ name: 'admin-partners'}">Partenaires</router-link>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'admin-navigation',

  computed: {
    ...mapGetters(['getCommittee', 'getSelfCommittee']),
    committee () {
      return this.getSelfCommittee && this.getCommittee(this.getSelfCommittee)
    }
  }
}

</script>

<style scoped lang="scss">
@import "src/assets/_shared_scss/colors";

nav.navbar {
  background-color: $bleufonce;
  padding: 0 5rem;
  height: 3rem;
  margin-bottom: 10px;

  @media screen and (max-width: 769px) {
    padding: 0 1rem;
  }

  .navbar-brand .navbar-item {
    font-weight: bold;
    font-size: large;
    color: white;
  }

  .navbar-menu .navbar-start {
    margin-right: unset;
    margin-left: auto;

    a.navbar-item {
      color: white;
      padding: 10px;
      margin: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: solid 1px $jaune;
      text-decoration: none;

      &:hover, &:focus, &.active {
        background-color: white;
        color: $bleufonce;
      }
    }
  }
}
</style>
