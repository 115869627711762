<template>
  <section class="shape">
    <div class="shape__container">
      <p class="shape__container__text">
        Le CSE est un organisme créé pour améliorer la vie des salariés au travail en leur donnant une voix dans les décisions importantes concernant leur emploi, formation, santé et sécurité, conditions de travail et participation aux résultats de l'entreprise. C'est un moyen pour les employés d'être impliqués et de faire entendre leur point de vue pour une amélioration continue de leur environnement de travail.
      </p>
    </div>
    </section>
</template>

<script>
export default {
  name: "shape"
}
</script>

<style scoped lang="scss">
@import "src/assets/_shared_scss/colors";

.shape {
  width: 100%;
  .shape__container {
    background-color: #FDD247;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 20px;
    margin-top: 100px;
    position: relative;
    .shape__container__text {
      color: white;
      text-align: justify;
      font-size: 1rem;
      max-width: 60ch;
    }
    ::before {
      content: "";
      position: absolute;
      top: -50px;
      left: 0;
      width: 100%;
      height: 100px;
      border-radius: 50%;
      background-color: $jaune;
      z-index: -1;
    }
  }
}
</style>
