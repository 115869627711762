import axios from 'axios'
import BaseService from './base'

export default class ArticleService extends BaseService {
    createArticle (body) {
        return axios.post(`${this.host}/articles/`, body)
    }

    editArticle (id, body) {
        return axios.put(`${this.host}/articles/${id}/`, body)
    }

    getArticle (id) {
        return axios.get(`${this.host}/articles/${id}/`)
    }
}
