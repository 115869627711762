<template>
  <div id="validated" class="is-fullwidth">
    <section class="hero is-danger">
      <div class="hero-body">
        <p class="title">
          Ce compte à bien été validé.
        </p>
        <p class="subtitle">
          La personne concernée à reçu un mail indiquant comment procéder à la création de son compte ainsi qu'à sa première connexion.
        </p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'validated'
}
</script>

<style scoped>
#validated {
  flex: 1;
  height: 100vh;
  background-image: url('~@/assets/images/fond.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 10rem;
}
</style>
