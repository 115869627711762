<template>
  <div class="card">
    <div class="card-image">
      <figure class="image pt-3">
        <img
            v-if="avatar"
            :src="avatar"
            alt="Avatar"
        >
        <img
            v-else
            src="~@/assets/images/user-placeholder.png"
            alt="Placeholder image"
        >
      </figure>
    </div>
    <div class="card-content">
      <div class="media">
        <div class="media-content">
          <p class="title is-4">{{ user.firstname }} {{ user.lastname }}</p>
          <p v-if="showProfession && user.profession" class="subtitle is-6">
            {{ user.profession }}
          </p>
          <p v-if="showProfessionCentralCommittee && user.professionCentralCommittee" class="subtitle is-6">
            {{ user.professionCentralCommittee }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'user-card',

  props: {
    user: {
      type: Object,
      required: true
    },
    showProfession: {
      type: Boolean,
      default: false
    },
    showProfessionCentralCommittee: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    avatar () {
      return this.user.avatar && this.user.avatar.path
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/_shared_scss/colors";

.card {
  height: 22rem;

  .card-image figure img {
    margin: 1rem auto;
    display: block;
    max-height:10rem;
    width: auto;
    height: auto;
  }

  .card-content .media .media-content {
    overflow: hidden;

    p {
      color: white;
    }
  }

  &:nth-child(even) {
    background-color: $bleu;
  }

  &:nth-child(odd) {
    background-color: $rose;
  }
}
</style>
