<template>
  <div id="validated" class="is-fullwidth">
    <section class="hero is-danger">
      <div class="hero-body">
        <p class="title">
          Votre demande à bien été envoyée.
        </p>
        <p class="subtitle">
          Vous recevrez un e-mail d'invitation lorsque le responsable de ce comité aura répondu favorablement à votre demande.
        </p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'asked'
}
</script>

<style scoped>
#validated {
  flex: 1;
  height: 100vh;
  background-image: url('~@/assets/images/fond.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 10rem;
}
</style>
