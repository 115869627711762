<template>
  <div class="home">
    <div class="pusher">
      <Slider></Slider>
      <Presentation></Presentation>
      <Services></Services>
      <Testimonial></Testimonial>
      <Shape></Shape>
    </div>
  </div>
</template>

<script>
import Slider from '@/components/home/slider/slider';
import Presentation from '@/components/home/presentation/presentation';
import Services from '@/components/home/nos_services/services';
import Testimonial from '@/components/home/testimonial/testimonial';
import Shape from "@/components/home/shape/shape";

export default {
  name: 'home',
  components: {
    Shape,
    Presentation,
    Slider,
    Services,
    Testimonial
  }
}
</script>

<style lang="scss">
  @import "src/assets/_shared_scss/colors";

</style>
