import axios from 'axios'

import BaseService from './base'

export default class AuthService extends BaseService {
    askAccount(body) {
        return axios.post(`${this.host}/auth/ask/`, body)
    }

    loginWithCredentials (body) {
        return axios.post(`${this.host}/auth/login/`, body)
    }

    register (body, headers) {
        return axios.post(`${this.host}/auth/register/`, body, { headers })
    }

    fetchSelf () {
        return axios.get(`${this.host}/users/self/`)
    }

    forgotPassword(body) {
        return axios.post(`${this.host}/auth/forgot-password`, body)
    }

    recoverPassword(body, headers) {
        return axios.post(`${this.host}/auth/recover-password/`, body, { headers })
    }

    editProfile (body) {
        return axios.put(`${this.host}/users/self/`, body)
    }
}
