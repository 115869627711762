<template>
  <div class="modal" :class="status">
    <div class="modal-background"></div>
    <div class="modal-content box">
      <div v-for="meeting in data" :key=meeting.id>
        <div class="media-content">
          <div class="content">
            <p>
              <strong>{{ meeting.title }}</strong>
              <br>
              prévue le {{ moment(meeting.startAt).format('lll') }}
            </p>
          </div>
        </div>
      </div>
      <button class="button is-pulled-right" @click="$emit('close')"> Fermer</button>
    </div>
    <button class="modal-close is-large" aria-label="close" @click="$emit('close')"></button>
  </div>
</template>

<script>

import moment from "moment";

export default {
  name: "modal_meetings_upcoming",
  data () {
    return {
      moment
    }
  },
  props : ['status', 'data']
}
</script>

<style scoped>

</style>