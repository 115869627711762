<template>
  <section id="reduc" class="redduce">
    <h2><span>R</span>educCE - Billetterie CE <br/>et Collectivité</h2>
    <div class="redduce__shape">
      <div class="redduce__shape__description">
        <p>
          ReducCe est un site de billetterie en ligne exclusivement destiné
          aux comités d’entreprises français et leurs ayants-droit.
          Notre offre de billetterie est composée de plus de 15000 offres
          dont des e-billets parcs de loisirs, de places de cinéma,
          forfaits de ski, ticket spectacle, concert, musées
          et événements sportifs.
        </p>
        <p>
          Les utilisateurs ont le choix parmi une grande
          diversité d’offres et le tout à un tarif
          préférentiel : des prix CE.
        </p>

        <a class="navbar-item" :class="{ 'is-loading': loadingRedirection }" @click="redirect">
          Accéder a mon site de billeterie
        </a>

        <img src="~@/assets/images/redduce.jpg" alt="redduce">
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      loadingRedirection: false
    }
  },

  methods: {
    ...mapActions(['fetchCommittees', 'fetchRedirection']),
    async redirect () {
      this.loadingRedirection = true

      try {
        const { url } = await this.fetchRedirection()
        window.open(url, '_blank');
      } catch (error) {
        console.error(error)
      }

      this.loadingRedirection = false
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/_shared_scss/colors";

.redduce {
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    color: $noir;
    font-weight: bolder;
    text-align: left;
    font-size: 2rem;
    margin-bottom: 4rem;

    span {
      font-size: 2.5rem;
      color: $rose;
    }
  }

  .redduce__shape {
    display: flex;
    transform: translate(-50%, -50%);
    margin: 200px 0;

    &__description {
      position: relative;
      background-color: $rose;
      padding: 2rem;

      p {
        text-align: justify;
        font-size: 1.2rem;
        color: white;
        max-width: 45ch;
      }

      &:before {
        content: "";
        position: absolute;
        width: 754px;
        height: 800px;
        left: -100 px;
        bottom: -129%;
        z-index: -1;
        background: url(~@/assets/images/svg/2.svg) 0 0 no-repeat;
      }

      a {
        position: absolute;
        bottom: -90%;
        left: 0;
        text-decoration: none;
        background-color: white;
        text-align: center;
        border: solid 1px $rose;
        width: 200px;
        color: $rose;
        padding: 15px;
        font-weight: bold;
      }

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        right: -90%;
        bottom: -90%;
        z-index: 1;
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .redduce {
    h2 {
      span {
      }
    }

    .redduce__shape {
      flex-direction: column;
      transform: initial;
      margin: 50px 0;
      &__description {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
        }

        &:before {
          display: none;
        }

        a {
          position: initial;
          bottom: initial;
          left: initial;
        }

        img {
          display: none;
        }
      }
    }
  }
}
</style>
